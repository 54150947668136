<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable persistent max-width="1180px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline ma-5" v-if="formType === 'CREATED'">Create New EMV</span>
                <span class="headline ma-5" v-else-if="formType === 'UPDATE'">Update EMV</span>
                <span class="headline ma-5" v-else-if="formType === 'VIEW'">View EMV</span>
            </v-card-title>
            <v-card-text>
                <v-form :disabled="formType === 'VIEW'" ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>EMV Parameter Index <span style="color: red;">*</span></span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :readonly="formType === 'UPDATE'" :label="formType === 'VIEW' ? '' : 'EMV Parameter Index'" maxLength="2" v-numeric-input v-model="form.emvParameterIndex" required :rules="[rules.requiredField]" data-cy="form-emv-parameter-index"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Scheme Reference <span style="color: red;">*</span></span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Scheme Reference'" maxLength="2" v-numeric-input v-model="form.schemeReference" :rules="[rules.requiredField]" data-cy="form-scheme-reference"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Issuer Reference <span style="color: red;">*</span></span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Issuer Reference'" maxLength="2" v-numeric-input v-model="form.issuerReference" :rules="[rules.requiredField]" data-cy="form-issuer-reference"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>TRM Data Present <span style="color: red;">*</span></span>
                                    </v-col>
                                    <v-col>
                                        <v-select :items="trmDataPresentList" v-model="form.trmDataPresent" :rules="[rules.requiredField]" :label="formType === 'VIEW' ? '' : 'Select TRM Data Present'" @change="getTrmDataPresent" data-cy="form-trm-data-present"></v-select>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Floor Limit</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Floor Limit'" maxLength="4" v-numeric-input v-model="form.floorLimit" data-cy="form-floor-limit"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>RS Threshold</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'RS Threshold'" maxLength="4" v-numeric-input v-model="form.rsThreshold" data-cy="form-rs-threshold"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Target RS %</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Target RS %'" maxLength="4" v-numeric-input v-model="form.targetRs" data-cy="form-target-rs"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Max Target RS %</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Max Target RS %'" maxLength="4" v-numeric-input v-model="form.maxTargetRs" data-cy="form-max-target-rs"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>TAC Default</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'TAC Default'" v-model="form.tacDefault" maxLength="10" :rules="inputRules" data-cy="form-tac-default"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>TAC Denial</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'TAC Denial'" v-model="form.tacDenial" maxLength="10" :rules="inputRules" data-cy="form-tac-denial"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>TAC Online</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'TAC Online'" v-model="form.tacOnline" maxLength="10" :rules="inputRules" data-cy="form-tac-online"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Default TDOL</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Default TDOL'" v-model="form.defaultTDOL" maxLength="65" :rules="inputRules" data-cy="form-default-tdol"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Default DDOL</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Default DDOL'" v-model="form.defaultDDOL" maxLength="65" :rules="inputRules" data-cy="form-dafault-ddol"></v-text-field>
                                    </v-col>              
                                </v-row>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Next Record</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Next Record'" maxLength="2" v-numeric-input v-model="form.nextRecord" data-cy="form-next-record"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>EMV Count</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'EMV Count'" maxLength="3" v-numeric-input v-model="form.emvCount" data-cy="form-emv-count"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term Country Code</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term Country Code'"  maxLength="5" :rules="inputRules" v-model="form.termCountryCode" data-cy="form-term-country-code"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term Currency Code</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term Currency Code'" maxLength="5" :rules="inputRules" v-model="form.termCurrencyCode" data-cy="form-term-currency-code"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term Capabilities</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term Capabilities'" maxLength="7" :rules="inputRules" v-model="form.termCapabilities" data-cy="form-term-capabilities"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term Add Capabilities</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term Add Capabilities'" maxLength="11" :rules="inputRules" v-model="form.termAddCapabilities" data-cy="form-term-add-capabilities"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term Type</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term Type'" maxLength="4" v-numeric-input v-model="form.termType" data-cy="form-term-type"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>MCC</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'MCC'" maxLength="4" v-numeric-input v-model="form.mcc" data-cy="form-mcc"></v-text-field>
                                    </v-col>              
                                </v-row>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="ma-5">
                <small v-if="formType != 'VIEW'">*indicates required field</small>
                <v-spacer></v-spacer>
                <v-btn color="primary" style="min-width: 100px;"  v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="primary" style="min-width: 100px;"  v-if="formType != 'VIEW'" v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        users: Array,
        emvs: Array,
    },
    directives: {
        'numeric-input': {
            inserted(el) {
                el.addEventListener('input', (event) => {
                const value = event.target.value;
                event.target.value = value.replace(/\D/g, ''); // Allow only numeric characters
                });
            }
        }
    },
    computed: {
        inputRules() {
            return [
                value => /^[a-zA-Z0-9]*$/.test(value) || 'Only alphanumeric characters are allowed',
            ];
        },
        populatedForm() {
            return {
                emvParameterIndex: this.form.emvParameterIndex,
                schemeReference: this.form.schemeReference,
                issuerReference: this.form.issuerReference,
                trmDataPresent: this.form.trmDataPresent,
                floorLimit: this.form.floorLimit,
                rsThreshold: this.form.rsThreshold,
                targetRs: this.form.targetRs,
                maxTargetRs: this.form.maxTargetRs,
                tacDefault: this.form.tacDefault,
                tacDenial: this.form.tacDenial,
                tacOnline: this.form.tacOnline,
                defaultTDOL: this.form.defaultTDOL,
                defaultDDOL: this.form.defaultDDOL,
                nextRecord: this.form.nextRecord,
                emvCount: this.form.emvCount,
                termCountryCode: this.form.termCountryCode,
                termCurrencyCode: this.form.termCurrencyCode,
                termCapabilities: this.form.termCapabilities,
                termAddCapabilities: this.form.termAddCapabilities,
                termType: this.form.termType,
                mcc: this.form.mcc,
                formType: this.formType
            }
        },
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            trmDataPresentList: ['1', '0'],
            form: {
            },
            // rules 
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection"
            },
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-emv', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        getTrmDataPresent: async function (val) {
            this.form.trmDataPresent = val
        },
        initializeForm() {
            this.e1 = 1;
            if (this.formType === 'CREATED') {
                this.form = {
                    emvParameterIndex: undefined,
                    schemeReference: undefined,
                    issuerReference: undefined,
                    trmDataPresent: undefined,
                    floorLimit: undefined,
                    rsThreshold: undefined,
                    targetRs: undefined,
                    maxTargetRs: undefined,
                    tacDefault: undefined,
                    tacDenial: undefined,
                    tacOnline: undefined,
                    defaultTDOL: undefined,
                    defaultDDOL: undefined,
                    nextRecord: undefined,
                    emvCount: undefined,
                    termCountryCode: undefined,
                    termCurrencyCode: undefined,
                    termCapabilities: undefined,
                    termAddCapabilities: undefined,
                    termType: undefined,
                    mcc: undefined,
                }
            } else if ((this.formType === 'UPDATE' || this.formType === 'VIEW') && this.selectedItem) {
                this.form = {
                    emvParameterIndex: this.selectedItem.emvId,
                    schemeReference: this.selectedItem.schemeReference,
                    issuerReference: this.selectedItem.issuerReference,
                    trmDataPresent: this.selectedItem.trmDataPresent.toString(),
                    floorLimit: this.selectedItem.floorLimit,
                    rsThreshold: this.selectedItem.rsThreshold,
                    targetRs: this.selectedItem.targetRs,
                    maxTargetRs: this.selectedItem.maxTargetRs,
                    tacDefault: this.selectedItem.tacDefault,
                    tacDenial: this.selectedItem.tacDenial,
                    tacOnline: this.selectedItem.tacOnline,
                    defaultTDOL: this.selectedItem.defaultTDOL,
                    defaultDDOL: this.selectedItem.defaultDDOL,
                    nextRecord: this.selectedItem.nextRecord,
                    emvCount: this.selectedItem.emvCount,
                    termCountryCode: this.selectedItem.termCountryCode,
                    termCurrencyCode: this.selectedItem.termCurrencyCode,
                    termCapabilities: this.selectedItem.termCapabilities,
                    termAddCapabilities: this.selectedItem.termAddCapabilities,
                    termType: this.selectedItem.termType,
                    mcc: this.selectedItem.mcc,
                }
            }
        }
    }
}
</script>