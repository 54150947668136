<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable persistent max-width="1180px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline ma-5" v-if="formType === 'CREATED'">Create New Card Scheme</span>
                <span class="headline ma-5" v-else-if="formType === 'UPDATE'">Update Card Scheme</span>
                <span class="headline ma-5" v-else-if="formType === 'VIEW'">View Card Scheme</span>
            </v-card-title>
            <v-card-text>
                <v-form :disabled="formType === 'VIEW'" ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Card Scheme Index <span style="color: red;">*</span></span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : ''" maxLength="2" v-numeric-input :readonly="formType === 'UPDATE'" v-model="form.cardSchemeIndex" required :rules="[rules.requiredField]"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Card Scheme ID <span style="color: red;">*</span></span>
                                    </v-col>
                                    <v-col>
                                        <v-select 
                                            :items="mappedTypeOfCardScheme" 
                                            item-text="displayText" 
                                            item-value="cardSchemeId" 
                                            v-model="form.cardSchemeId" 
                                            :label="formType === 'VIEW' ? '' : 'Select Card Scheme ID'" 
                                            required :rules="[rules.requiredField]" 
                                            data-cy="form-type">
                                        </v-select>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>EPT Record <span style="color: red;">*</span></span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'EPT Record'" maxLength="2" v-numeric-input v-model="form.eptRecord" required :rules="[rules.requiredField]" data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Acquirer ID <span style="color: red;">*</span></span>
                                    </v-col>
                                    <v-col>
                                        <v-select 
                                            item-text="acquirerName" 
                                            item-value="acquirerId" 
                                            :items="acquirerList" 
                                            v-model="form.acquirer" 
                                            :label="formType === 'VIEW' ? '' : 'Select Acquirer ID'" 
                                            return-object 
                                            required :rules="[rules.requiredField]" 
                                            data-cy="form-type">
                                        </v-select>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>RID</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="form.rid" :label="formType === 'VIEW' ? '' : 'RID'" maxLength="10" :rules="inputRules" data-cy="form-type"></v-text-field>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Index 1</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'CAPK Index 1'"  maxLength="2" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.capkIndex1"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK File 1</span>
                                    </v-col>
                                    <v-col>
                                        <v-btn :disabled="formType === 'VIEW'" color="#1d6499" :style="{ color: 'white' }" :loading="isSelecting[0]" @click="handleFileImport(0)">
                                            {{ form.capkFile1 }}
                                        </v-btn>
                                    <input class="d-none" type="file" id="file" ref="uploaderCapk1" v-on:change="handleFileUpload(0)"/>
                                        <v-btn class="ml-2" :disabled="formType === 'VIEW' || form.capkFile1 === 'Browse'" color="red" :style="{ color: 'white' }" @click="removeFileUploaded(0)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-col>           
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Exp Date 1</span>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="expiryDateMenuCAPK1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-expiry">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field  single-line :label="formType === 'VIEW' ? '' : 'Expiration date'" append-icon="mdi-calendar" readonly :value="displayExpirationDateCAPK1" v-on="on" data-cy="date-menu-expiry"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="form.capkExptDate1" no-title @input="expiryDateMenuCAPK1 = false" :min="minExpiryDate" :max="maxExpiryDate" data-cy="date-menu-expiry"></v-date-picker>
                                        </v-menu>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Index 2</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'CAPK Index 2'" maxLength="2" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.capkIndex2"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK File 2</span>
                                    </v-col>
                                    <v-col>
                                        <v-btn :disabled="formType === 'VIEW'" color="#1d6499" :style="{ color: 'white' }" :loading="isSelecting[1]" @click="handleFileImport(1)">
                                            {{ form.capkFile2 }}
                                        </v-btn>
                                    <input class="d-none" type="file" id="file" ref="uploaderCapk2" v-on:change="handleFileUpload(1)"/>
                                        <v-btn class="ml-2" :disabled="formType === 'VIEW' || form.capkFile2 === 'Browse'" color="red" :style="{ color: 'white' }" @click="removeFileUploaded(1)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-col>             
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Exp Date 2</span>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="expiryDateMenuCAPK2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-expiry">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field  single-line :label="formType === 'VIEW' ? '' : 'Expiration date'" append-icon="mdi-calendar" readonly :value="displayExpirationDateCAPK2" v-on="on" data-cy="date-menu-expiry"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="form.capkExptDate2" no-title @input="expiryDateMenuCAPK2 = false" :min="minExpiryDate" :max="maxExpiryDate" data-cy="date-menu-expiry"></v-date-picker>
                                        </v-menu>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Index 3</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'CAPK Index 3'" maxLength="2" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.capkIndex3"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK File 3</span>
                                    </v-col>
                                    <v-col>
                                        <v-btn :disabled="formType === 'VIEW'" color="#1d6499" :style="{ color: 'white' }" :loading="isSelecting[2]" @click="handleFileImport(2)">
                                            {{ form.capkFile3 }}
                                        </v-btn>
                                    <input class="d-none" type="file" id="file" ref="uploaderCapk3" v-on:change="handleFileUpload(2)"/>
                                        <v-btn class="ml-2" :disabled="formType === 'VIEW' || form.capkFile3 === 'Browse'" color="red" :style="{ color: 'white' }" @click="removeFileUploaded(2)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-col>             
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Exp Date 3</span>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="expiryDateMenuCAPK3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-expiry">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field  single-line :label="formType === 'VIEW' ? '' : 'Expiration date'" append-icon="mdi-calendar" readonly :value="displayExpirationDateCAPK3" v-on="on" data-cy="date-menu-expiry"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="form.capkExptDate3" no-title @input="expiryDateMenuCAPK3 = false" :min="minExpiryDate" :max="maxExpiryDate" data-cy="date-menu-expiry"></v-date-picker>
                                        </v-menu>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Index 4</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'CAPK Index 4'" maxLength="2" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.capkIndex4"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK File 4</span>
                                    </v-col>
                                    <v-col>
                                        <v-btn :disabled="formType === 'VIEW'" color="#1d6499" :style="{ color: 'white' }" :loading="isSelecting[3]" @click="handleFileImport(3)">
                                            {{ form.capkFile4 }}
                                        </v-btn>
                                    <input class="d-none" type="file" id="file" ref="uploaderCapk4" v-on:change="handleFileUpload(3)"/>
                                        <v-btn class="ml-2" :disabled="formType === 'VIEW' || form.capkFile4 === 'Browse'" color="red" :style="{ color: 'white' }" @click="handleFileImport(3)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-col>             
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Exp Date 4</span>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="expiryDateMenuCAPK4" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-expiry">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field  single-line :label="formType === 'VIEW' ? '' : 'Expiration date'" append-icon="mdi-calendar" readonly :value="displayExpirationDateCAPK4" v-on="on" data-cy="date-menu-expiry"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="form.capkExptDate4" no-title @input="expiryDateMenuCAPK4 = false" :min="minExpiryDate" :max="maxExpiryDate" data-cy="date-menu-expiry"></v-date-picker>
                                        </v-menu>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Index 5</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'CAPK Index 5'" maxLength="2" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.capkIndex5"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK File 5</span>
                                    </v-col>
                                    <v-col>
                                        <v-btn :disabled="formType === 'VIEW'" color="#1d6499" :style="{ color: 'white' }" :loading="isSelecting[4]" @click="handleFileImport(4)">
                                            {{ form.capkFile5 }}
                                        </v-btn>
                                    <input class="d-none" type="file" id="file" ref="uploaderCapk5" v-on:change="handleFileUpload(4)"/>
                                        <v-btn class="ml-2" :disabled="formType === 'VIEW' || form.capkFile5 === 'Browse'" color="red" :style="{ color: 'white' }" @click="handleFileImport(4)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-col>             
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Exp Date 5</span>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="expiryDateMenuCAPK5" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-expiry">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field  single-line :label="formType === 'VIEW' ? '' : 'Expiration date'" append-icon="mdi-calendar" readonly :value="displayExpirationDateCAPK5" v-on="on" data-cy="date-menu-expiry"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="form.capkExptDate5" no-title @input="expiryDateMenuCAPK5 = false" :min="minExpiryDate" :max="maxExpiryDate" data-cy="date-menu-expiry"></v-date-picker>
                                        </v-menu>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Index 6</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'CAPK Index 6'" maxLength="2" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.capkIndex6"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK File 6</span>
                                    </v-col>
                                    <v-col>
                                        <v-btn :disabled="formType === 'VIEW'" color="#1d6499" :style="{ color: 'white' }" :loading="isSelecting[5]" @click="handleFileImport(5)">
                                            {{ form.capkFile6 }}
                                        </v-btn>
                                    <input class="d-none" type="file" id="file" ref="uploaderCapk6" v-on:change="handleFileUpload(5)"/>
                                        <v-btn class="ml-2" :disabled="formType === 'VIEW' || form.capkFile6 === 'Browse'" color="red" :style="{ color: 'white' }" @click="handleFileImport(5)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-col>             
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Exp Date 6</span>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="expiryDateMenuCAPK6" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-expiry">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field  single-line :label="formType === 'VIEW' ? '' : 'Expiration date'" append-icon="mdi-calendar" readonly :value="displayExpirationDateCAPK6" v-on="on" data-cy="date-menu-expiry"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="form.capkExptDate6" no-title @input="expiryDateMenuCAPK6 = false" :min="minExpiryDate" :max="maxExpiryDate" data-cy="date-menu-expiry"></v-date-picker>
                                        </v-menu>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Index 7</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'CAPK Index 7'" maxLength="2" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.capkIndex7"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK File 7</span>
                                    </v-col>
                                    <v-col>
                                        <v-btn :disabled="formType === 'VIEW'" color="#1d6499" :style="{ color: 'white' }" :loading="isSelecting[6]" @click="handleFileImport(6)">
                                            {{ form.capkFile7 }}
                                        </v-btn>
                                    <input class="d-none" type="file" id="file" ref="uploaderCapk7" v-on:change="handleFileUpload(6)"/>
                                        <v-btn class="ml-2" :disabled="formType === 'VIEW' || form.capkFile7 === 'Browse'" color="red" :style="{ color: 'white' }" @click="handleFileImport(6)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-col>             
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Exp Date 7</span>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="expiryDateMenuCAPK7" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-expiry">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field  single-line :label="formType === 'VIEW' ? '' : 'Expiration date'" append-icon="mdi-calendar" readonly :value="displayExpirationDateCAPK7" v-on="on" data-cy="date-menu-expiry"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="form.capkExptDate7" no-title @input="expiryDateMenuCAPK7 = false" :min="minExpiryDate" :max="maxExpiryDate" data-cy="date-menu-expiry"></v-date-picker>
                                        </v-menu>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Index 8</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'CAPK Index 8'" maxLength="2" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.capkIndex8"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK File 8</span>
                                    </v-col>
                                    <v-col>
                                        <v-btn :disabled="formType === 'VIEW'" color="#1d6499" :style="{ color: 'white' }" :loading="isSelecting[7]" @click="handleFileImport(7)">
                                            {{ form.capkFile8 }}
                                        </v-btn>
                                    <input class="d-none" type="file" id="file" ref="uploaderCapk8" v-on:change="handleFileUpload(7)"/>
                                        <v-btn class="ml-2" :disabled="formType === 'VIEW' || form.capkFile8 === 'Browse'" color="red" :style="{ color: 'white' }" @click="handleFileImport(7)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-col>             
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Exp Date 8</span>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="expiryDateMenuCAPK8" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-expiry">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field  single-line :label="formType === 'VIEW' ? '' : 'Expiration date'" append-icon="mdi-calendar" readonly :value="displayExpirationDateCAPK8" v-on="on" data-cy="date-menu-expiry"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="form.capkExptDate8" no-title @input="expiryDateMenuCAPK8 = false" :min="minExpiryDate" :max="maxExpiryDate" data-cy="date-menu-expiry"></v-date-picker>
                                        </v-menu>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Index 9</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'CAPK Index 9'" maxLength="2" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.capkIndex9"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK File 9</span>
                                    </v-col>
                                    <v-col>
                                        <v-btn :disabled="formType === 'VIEW'" color="#1d6499" :style="{ color: 'white' }" :loading="isSelecting[8]" @click="handleFileImport(8)">
                                            {{ form.capkFile9 }}
                                        </v-btn>
                                    <input class="d-none" type="file" id="file" ref="uploaderCapk9" v-on:change="handleFileUpload(8)"/>
                                        <v-btn class="ml-2" :disabled="formType === 'VIEW' || form.capkFile9 === 'Browse'" color="red" :style="{ color: 'white' }" @click="handleFileImport(8)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-col>             
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Exp Date 9</span>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="expiryDateMenuCAPK9" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-expiry">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field  single-line :label="formType === 'VIEW' ? '' : 'Expiration date'" append-icon="mdi-calendar" readonly :value="displayExpirationDateCAPK9" v-on="on" data-cy="date-menu-expiry"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="form.capkExptDate9" no-title @input="expiryDateMenuCAPK9 = false" :min="minExpiryDate" :max="maxExpiryDate" data-cy="date-menu-expiry"></v-date-picker>
                                        </v-menu>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Index 10</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'CAPK Index 10'" maxLength="2" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.capkIndex10"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK File 10</span>
                                    </v-col>
                                    <v-col>
                                        <v-btn :disabled="formType === 'VIEW'" color="#1d6499" :style="{ color: 'white' }" :loading="isSelecting[9]" @click="handleFileImport(9)">
                                            {{ form.capkFile10 }}
                                        </v-btn>
                                    <input class="d-none" type="file" id="file" ref="uploaderCapk10" v-on:change="handleFileUpload(9)"/>
                                        <v-btn class="ml-2" :disabled="formType === 'VIEW' || form.capkFile10 === 'Browse'" color="red" :style="{ color: 'white' }" @click="handleFileImport(9)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-col>             
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>CAPK Exp Date 10</span>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="expiryDateMenuCAPK10" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-expiry">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field  single-line :label="formType === 'VIEW' ? '' : 'Expiration date'" append-icon="mdi-calendar" readonly :value="displayExpirationDateCAPK10" v-on="on" data-cy="date-menu-expiry"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="form.capkExptDate10" no-title @input="expiryDateMenuCAPK10 = false" :min="minExpiryDate" :max="maxExpiryDate" data-cy="date-menu-expiry"></v-date-picker>
                                        </v-menu>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Supported AID 1</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Supported AID 1'" maxLength="34" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.supportedAid1"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Partial Name Flag 1</span>
                                    </v-col>
                                    <v-col>
                                        <v-select :items="partialNameFlag" v-model="form.partialNameFlag1" :label="formType === 'VIEW' ? '' : 'Select Partial Name Flag 1'" data-cy="form-type"></v-select>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term AVN 1</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term AVN 1'" maxLength="5" :rules="inputRules" v-model="form.termAvn1"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>2nd Term AVN 1</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : '2nd Term AVN 1'" maxLength="5" :rules="inputRules" v-model="form.secondTermAvn1"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Supported AID 2</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Supported AID 2'" maxLength="34" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.supportedAid2"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Partial Name Flag 2</span>
                                    </v-col>
                                    <v-col>
                                        <v-select :items="partialNameFlag" v-model="form.partialNameFlag2" :label="formType === 'VIEW' ? '' : 'Select Partial Name Flag 2'" data-cy="form-type"></v-select>
                                    </v-col>               
                                </v-row>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term AVN 2</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term AVN 2'" maxLength="5" :rules="inputRules" v-model="form.termAvn2"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>2nd Term AVN 2</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : '2nd Term AVN 2'" maxLength="5" :rules="inputRules" v-model="form.secondTermAvn2"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Supported AID 3</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Supported AID 3'" maxLength="34" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.supportedAid3"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Partial Name Flag 3</span>
                                    </v-col>
                                    <v-col>
                                        <v-select :items="partialNameFlag" v-model="form.partialNameFlag3" :label="formType === 'VIEW' ? '' : 'Select Partial Name Flag 3'" data-cy="form-type"></v-select>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term AVN 3</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term AVN 3'" maxLength="5" :rules="inputRules" v-model="form.termAvn3"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>2nd Term AVN 3</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : '2nd Term AVN 3'" maxLength="5" :rules="inputRules" v-model="form.secondTermAvn3"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Supported AID 4</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Supported AID 4'" maxLength="34" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.supportedAid4"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Partial Name Flag 4</span>
                                    </v-col>
                                    <v-col>
                                        <v-select :items="partialNameFlag" v-model="form.partialNameFlag4" :label="formType === 'VIEW' ? '' : 'Select Partial Name Flag 4'" data-cy="form-type"></v-select>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term AVN 5</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term AVN 4'" maxLength="5" :rules="inputRules" v-model="form.termAvn4"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>2nd Term AVN 4</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : '2nd Term AVN 4'" maxLength="5" :rules="inputRules" v-model="form.secondTermAvn4"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Supported AID 5</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Supported AID 5'" maxLength="34" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.supportedAid5"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Partial Name Flag 5</span>
                                    </v-col>
                                    <v-col>
                                        <v-select :items="partialNameFlag" v-model="form.partialNameFlag5" :label="formType === 'VIEW' ? '' : 'Select Partial Name Flag 5'" data-cy="form-type"></v-select>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term AVN 5</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term AVN 5'" maxLength="5" :rules="inputRules" v-model="form.termAvn5"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>2nd Term AVN 5</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : '2nd Term AVN 5'" maxLength="5" :rules="inputRules" v-model="form.secondTermAvn5"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Supported AID 6</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Supported AID 6'" maxLength="34" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.supportedAid6"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Partial Name Flag 6</span>
                                    </v-col>
                                    <v-col>
                                        <v-select :items="partialNameFlag" v-model="form.partialNameFlag6" :label="formType === 'VIEW' ? '' : 'Select Partial Name Flag 6'" data-cy="form-type"></v-select>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term AVN 1</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term AVN 6'" maxLength="5" :rules="inputRules" v-model="form.termAvn6"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>2nd Term AVN 6</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : '2nd Term AVN 6'" maxLength="5" :rules="inputRules" v-model="form.secondTermAvn6"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Supported AID 7</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Supported AID 7'" maxLength="34" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.supportedAid7"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Partial Name Flag 7</span>
                                    </v-col>
                                    <v-col>
                                        <v-select :items="partialNameFlag" v-model="form.partialNameFlag7" :label="formType === 'VIEW' ? '' : 'Select Partial Name Flag 7'" data-cy="form-type"></v-select>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term AVN 7</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term AVN 7'" maxLength="5" :rules="inputRules" v-model="form.termAvn7"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>2nd Term AVN 7</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : '2nd Term AVN 7'" maxLength="5" :rules="inputRules" v-model="form.secondTermAvn7"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Supported AID 8</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Supported AID 8'" maxLength="34" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.supportedAid8"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Partial Name Flag 8</span>
                                    </v-col>
                                    <v-col>
                                        <v-select :items="partialNameFlag" v-model="form.partialNameFlag8" :label="formType === 'VIEW' ? '' : 'Select Partial Name Flag 8'" data-cy="form-type"></v-select>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term AVN 8</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term AVN 8'" maxLength="5" :rules="inputRules" v-model="form.termAvn8"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>2nd Term AVN 8</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : '2nd Term AVN 8'" maxLength="5" :rules="inputRules" v-model="form.secondTermAvn8"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Supported AID 9</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Supported AID 9'" maxLength="34" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.supportedAid9"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Partial Name Flag 9</span>
                                    </v-col>
                                    <v-col>
                                        <v-select :items="partialNameFlag" v-model="form.partialNameFlag9" :label="formType === 'VIEW' ? '' : 'Select Partial Name Flag 9'"  data-cy="form-type"></v-select>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term AVN 9</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term AVN 9'" maxLength="5" :rules="inputRules" v-model="form.termAvn9"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>2nd Term AVN 9</span> 
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : '2nd Term AVN 9'" maxLength="5" :rules="inputRules" v-model="form.secondTermAvn9"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Supported AID 10</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Supported AID 10'" maxLength="34" :rules="inputRules" :readonly="formType === 'UPDATE'" v-model="form.supportedAid10"  data-cy="form-card-scheme-id"></v-text-field>
                                    </v-col>              
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Partial Name Flag 10</span>
                                    </v-col>
                                    <v-col>
                                        <v-select :items="partialNameFlag" v-model="form.partialNameFlag10" :label="formType === 'VIEW' ? '' : 'Select Partial Name Flag 10'" data-cy="form-type"></v-select>
                                    </v-col>               
                                </v-row>
                                <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>Term AVN 10</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Term AVN 10'" maxLength="5" :rules="inputRules" v-model="form.termAvn10"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                                 <v-row class="align-center" no-gutters>
                                    <v-col class="ml-5">
                                        <span>2nd Term AVN 10</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :label="formType === 'VIEW' ? '' : '2nd Term AVN 10'" maxLength="5" :rules="inputRules" v-model="form.secondTermAvn10"  data-cy="form-model"></v-text-field>
                                    </v-col>              
                                </v-row>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="ma-5">
                <small v-if="formType != 'VIEW'">*indicates required field</small>
                <v-spacer></v-spacer>
                <v-btn color="primary" style="min-width: 100px;"  v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="primary" style="min-width: 100px;" v-if="formType != 'VIEW'" v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        users: Array,
        cardSchemes: Array,
        acquirers: Array,
    },
    directives: {
        'numeric-input': {
            inserted(el) {
                el.addEventListener('input', (event) => {
                const value = event.target.value;
                event.target.value = value.replace(/\D/g, ''); // Allow only numeric characters
                });
            }
        }
    },
    computed: {
        mappedTypeOfCardScheme() {
            return this.typeOfCardScheme.map(value => ({
                cardSchemeId: value,
                displayText: this.mapSchemeId(value)
            }));
        },
        inputRules() {
            return [
                value => /^[a-zA-Z0-9]*$/.test(value) || 'Only alphanumeric characters are allowed',
            ];
        },
        //temporary computed data -- 
        displayExpirationDateCAPK1() {
            return this.form.capkExptDate1
        },
        displayExpirationDateCAPK2() {
            return this.form.capkExptDate2
        },
        displayExpirationDateCAPK3() {
            return this.form.capkExptDate3
        },
        displayExpirationDateCAPK4() {
            return this.form.capkExptDate4
        },
        displayExpirationDateCAPK5() {
            return this.form.capkExptDate5
        },
        displayExpirationDateCAPK6() {
            return this.form.capkExptDate6
        },
        displayExpirationDateCAPK7() {
            return this.form.capkExptDate7
        },
        displayExpirationDateCAPK8() {
            return this.form.capkExptDate8
        },
        displayExpirationDateCAPK9() {
            return this.form.capkExptDate9
        },
        displayExpirationDateCAPK10() {
            return this.form.capkExptDate10
        },
        populatedForm() {
            return {
                cardSchemeIndex: this.form.cardSchemeIndex,
                cardSchemeId: this.form.cardSchemeId,
                eptRecord: this.form.eptRecord,
                acquirer: this.form.acquirer,
                rid: this.form.rid,
                capkIndex1: this.form.capkIndex1,
                capkFile1: this.form.capkFile1 == 'Browse' ? '' : this.form.capkFile1,
                capkExptDate1: this.form.capkExptDate1,
                capkIndex2: this.form.capkIndex2,
                capkFile2: this.form.capkFile2 == 'Browse' ? '' : this.form.capkFile2,
                capkExptDate2: this.form.capkExptDate2,
                capkIndex3: this.form.capkIndex3,
                capkFile3: this.form.capkFile3 == 'Browse' ? '' : this.form.capkFile3,
                capkExptDate3: this.form.capkExptDate3,
                capkIndex4: this.form.capkIndex4,
                capkFile4: this.form.capkFile4 == 'Browse' ? '' : this.form.capkFile4,
                capkExptDate4: this.form.capkExptDate4,
                capkIndex5: this.form.capkIndex5,
                capkFile5: this.form.capkFile5 == 'Browse' ? '' : this.form.capkFile5,
                capkExptDate5: this.form.capkExptDate5,
                capkIndex6: this.form.capkIndex6,
                capkFile6: this.form.capkFile6 == 'Browse' ? '' : this.form.capkFile6,
                capkExptDate6: this.form.capkExptDate6,
                capkIndex7: this.form.capkIndex7,
                capkFile7: this.form.capkFile7 == 'Browse' ? '' : this.form.capkFile7,
                capkExptDate7: this.form.capkExptDate7,
                capkIndex8: this.form.capkIndex8,
                capkFile8: this.form.capkFile8 == 'Browse' ? '' : this.form.capkFile8,
                capkExptDate8: this.form.capkExptDate8,
                capkIndex9: this.form.capkIndex9,
                capkFile9: this.form.capkFile9 == 'Browse' ? '' : this.form.capkFile9,
                capkExptDate9: this.form.capkExptDate9,
                capkIndex10: this.form.capkIndex10,
                capkFile10: this.form.capkFile10 == 'Browse' ? '' : this.form.capkFile10,
                capkExptDate10: this.form.capkExptDate10,
                supportedAid1: this.form.supportedAid1,
                partialNameFlag1: this.form.partialNameFlag1,
                termAvn1: this.form.termAvn1,
                secondTermAvn1: this.form.secondTermAvn1,
                supportedAid2: this.form.supportedAid2,
                partialNameFlag2: this.form.partialNameFlag2,
                termAvn2: this.form.termAvn2,
                secondTermAvn2: this.form.secondTermAvn2,
                supportedAid3: this.form.supportedAid3,
                partialNameFlag3: this.form.partialNameFlag3,
                termAvn3: this.form.termAvn3,
                secondTermAvn3: this.form.secondTermAvn3,
                supportedAid4: this.form.supportedAid4,
                partialNameFlag4: this.form.partialNameFlag4,
                termAvn4: this.form.termAvn4,
                secondTermAvn4: this.form.secondTermAvn4,
                supportedAid5: this.form.supportedAid5,
                partialNameFlag5: this.form.partialNameFlag5,
                termAvn5: this.form.termAvn5,
                secondTermAvn5: this.form.secondTermAvn5,
                supportedAid6: this.form.supportedAid6,
                partialNameFlag6: this.form.partialNameFlag6,
                termAvn6: this.form.termAvn6,
                secondTermAvn6: this.form.secondTermAvn6,
                supportedAid7: this.form.supportedAid7,
                partialNameFlag7: this.form.partialNameFlag7,
                termAvn7: this.form.termAvn7,
                secondTermAvn7: this.form.secondTermAvn7,
                supportedAid8: this.form.supportedAid8,
                partialNameFlag8: this.form.partialNameFlag8,
                termAvn8: this.form.termAvn8,
                secondTermAvn8: this.form.secondTermAvn8,
                supportedAid9: this.form.supportedAid9,
                partialNameFlag9: this.form.partialNameFlag9,
                termAvn9: this.form.termAvn9,
                secondTermAvn9: this.form.secondTermAvn9,
                supportedAid10: this.form.supportedAid10,
                partialNameFlag10: this.form.partialNameFlag10,
                termAvn10: this.form.termAvn10,
                secondTermAvn10: this.form.secondTermAvn10, 
                formType: this.formType
            }
        },
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        acquirers: {
            handler(value) {
                this.acquirerList = value
            }
        },
        deep: true,
    },
    data() {
        return {
            //file handler
            label: 'Browse',
            isFileUploaded: false,
            isSelecting: [false, false, false, false, false ,false, false, false, false, false],
            file: '',
            //min-max date variables
            minExpiryDate: "1997-01-01",
            maxExpiryDate: undefined,
            
            // rules 
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection"
            },

            //CAPK variables
            expiryDateMenuCAPK1: false,
            expiryDateValCAPK1: undefined,
            expiryDateMenuCAPK2: false,
            expiryDateValCAPK2: undefined,
            expiryDateMenuCAPK3: false,
            expiryDateValCAPK3: undefined,
            expiryDateMenuCAPK4: false,
            expiryDateValCAPK4: undefined,
            expiryDateMenuCAPK5: false,
            expiryDateValCAPK5: undefined,
            expiryDateMenuCAPK6: false,
            expiryDateValCAPK6: undefined,
            expiryDateMenuCAPK7: false,
            expiryDateValCAPK7: undefined,
            expiryDateMenuCAPK8: false,
            expiryDateValCAPK8: undefined,
            expiryDateMenuCAPK9: false,
            expiryDateValCAPK9: undefined,
            expiryDateMenuCAPK10: false,
            expiryDateValCAPK10: undefined,
            //---
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            showDateInstalledMenu: false,
            showASTMenu: false,
            getTypeOfPartial: ['1','0'],
            typeOfCardScheme: ['1', '2', '3', '4'],
            partialNameFlag: ['0','1'],
            acquirerList: this.acquirers ?? [],
            form: {
                acquirer: {}
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
            },
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        mapSchemeId(value) {
            switch (value) {
                case "1":
                    return "QR PH";
                case "2":
                    return "Visa";
                case "3":
                    return "Master Card";
                default:
                    return "Bancnet";
            }
        },
        //remove file uploaded 
        removeFileUploaded(value) {
            switch (value) {
                case 0:
                    this.form.capkFile1 = 'Browse'
                    break;
                case 1:
                    this.form.capkFile2 = 'Browse'
                    break;
                case 2: 
                    this.form.capkFile3 = 'Browse'
                    break;      
                case 3:
                    this.form.capkFile4 = 'Browse' 
                    break;      
                case 4:
                    this.form.capkFile5 = 'Browse' 
                    break;    
                case 5:
                    this.form.capkFile6 = 'Browse' 
                    break;    
                case 6:
                    this.form.capkFile7 = 'Browse' 
                    break;
                case 7:
                    this.form.capkFile8 = 'Browse' 
                    break;
                case 8:
                    this.form.capkFile9 = 'Browse'
                    break;
                case 9:
                    this.form.capkFile10 = 'Browse'
                    break;
            }
        },
        //file handler
        handleFileImport(value) {
            this.isSelecting[value] = true;
            console.log("File Select Value: ", value)

            // After obtaining the focus when closing the FilePicker, return the button state to normal
            window.addEventListener('focus', () => {
                this.isSelecting[value] = false
            }, { once: true });
            
            // Trigger click on the FileInput
            
            switch (value) {
                case 0:
                    this.$refs.uploaderCapk1.setAttribute('accept', '.capk');
                    this.$refs.uploaderCapk1.click();
                    break;
                case 1:
                    this.$refs.uploaderCapk2.setAttribute('accept', '.capk');
                    this.$refs.uploaderCapk2.click();
                    break;
                case 2: 
                    this.$refs.uploaderCapk3.setAttribute('accept', '.capk');
                    this.$refs.uploaderCapk3.click();
                    break;      
                case 3:
                    this.$refs.uploaderCapk4.setAttribute('accept', '.capk');
                    this.$refs.uploaderCapk4.click(); 
                    break;      
                case 4:
                    this.$refs.uploaderCapk5.setAttribute('accept', '.capk');
                    this.$refs.uploaderCapk5.click();
                    break;    
                case 5:
                    this.$refs.uploaderCapk6.setAttribute('accept', '.capk');
                    this.$refs.uploaderCapk6.click();
                    break;    
                case 6:
                    this.$refs.uploaderCapk7.setAttribute('accept', '.capk');
                    this.$refs.uploaderCapk7.click(); 
                    break;
                case 7:
                    this.$refs.uploaderCapk8.setAttribute('accept', '.capk');
                    this.$refs.uploaderCapk8.click();
                    break;
                case 8:
                    this.$refs.uploaderCapk9.setAttribute('accept', '.capk');
                    this.$refs.uploaderCapk9.click();
                    break;
                case 9:
                    this.$refs.uploaderCapk10.setAttribute('accept', '.capk');
                    this.$refs.uploaderCapk10.click();
                    break;
            }
        },
        handleFileUpload(value) {
            console.log("File Upload Value: ", value)
            switch (value) {
                case 0:
                    this.file = this.$refs.uploaderCapk1.files[0];
                    this.form.capkFile1 = `${this.file.name}`
                    break;
                case 1:
                    this.file = this.$refs.uploaderCapk2.files[0];
                    this.form.capkFile2 = `${this.file.name}`
                    break;
                case 2: 
                    this.file = this.$refs.uploaderCapk3.files[0];
                    this.form.capkFile3 = `${this.file.name}`
                    break;      
                case 3:
                    this.file = this.$refs.uploaderCapk4.files[0];
                    this.form.capkFile4 = `${this.file.name}` 
                    break;      
                case 4:
                    this.file = this.$refs.uploaderCapk5.files[0];
                    this.form.capkFile5 = `${this.file.name}` 
                    break;    
                case 5:
                    this.file = this.$refs.uploaderCapk6.files[0];
                    this.form.capkFile6 = `${this.file.name}` 
                    break;    
                case 6:
                    this.file = this.$refs.uploaderCapk7.files[0];
                    this.form.capkFile7 = `${this.file.name}` 
                    break;
                case 7:
                    this.file = this.$refs.uploaderCapk8.files[0];
                    this.form.capkFile8 = `${this.file.name}` 
                    break;
                case 8:
                    this.file = this.$refs.uploaderCapk9.files[0];
                    this.form.capkFile9 = `${this.file.name}`
                    break;
                case 9:
                    this.file = this.$refs.uploaderCapk10.files[0];
                    this.form.capkFile10 = `${this.file.name}`
                    break;
            }
        },
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-card-scheme', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            this.e1 = 1;
            if (this.formType === 'CREATED') {
                this.form = {
                    cardSchemeIndex: undefined,
                    cardSchemeId: undefined,
                    eptRecord: undefined,
                    acquirer: undefined,
                    rid: undefined,
                    capkIndex1: undefined,
                    capkFile1: 'Browse',
                    capkExptDate1: undefined,
                    capkIndex2: undefined,
                    capkFile2: 'Browse',
                    capkExptDate2: undefined,
                    capkIndex3: undefined,
                    capkFile3: 'Browse',
                    capkExptDate3: undefined,
                    capkIndex4: undefined,
                    capkFile4: 'Browse',
                    capkExptDate4: undefined,
                    capkIndex5: undefined,
                    capkFile5: 'Browse',
                    capkExptDate5: undefined,
                    capkIndex6: undefined,
                    capkFile6: 'Browse',
                    capkExptDate6: undefined,
                    capkIndex7: undefined,
                    capkFile7: 'Browse',
                    capkExptDate7: undefined,
                    capkIndex8: undefined,
                    capkFile8: 'Browse',
                    capkExptDate8: undefined,
                    capkIndex9: undefined,
                    capkFile9: 'Browse',
                    capkExptDate9: undefined,
                    capkIndex10: undefined,
                    capkFile10: 'Browse',
                    capkExptDate10: undefined,
                    supportedAid1: undefined,
                    partialNameFlag1: undefined,
                    termAvn1: undefined,
                    secondTermAvn1: undefined,
                    supportedAid2: undefined,
                    partialNameFlag2: undefined,
                    termAvn2: undefined,
                    secondTermAvn2: undefined,
                    supportedAid3: undefined,
                    partialNameFlag3: undefined,
                    termAvn3: undefined,
                    secondTermAvn3: undefined,
                    supportedAid4: undefined,
                    partialNameFlag4: undefined,
                    termAvn4: undefined,
                    secondTermAvn4: undefined,
                    supportedAid5: undefined,
                    partialNameFlag5: undefined,
                    termAvn5: undefined,
                    secondTermAvn5: undefined,
                    supportedAid6: undefined,
                    partialNameFlag6: undefined,
                    termAvn6: undefined,
                    secondTermAvn6: undefined,
                    supportedAid7: undefined,
                    partialNameFlag7: undefined,
                    termAvn7: undefined,
                    secondTermAvn7: undefined,
                    supportedAid8: undefined,
                    partialNameFlag8: undefined,
                    termAvn8: undefined,
                    secondTermAvn8: undefined,
                    supportedAid9: undefined,
                    partialNameFlag9: undefined,
                    termAvn9: undefined,
                    secondTermAvn9: undefined,
                    supportedAid10: undefined,
                    partialNameFlag10: undefined,
                    termAvn10: undefined,
                    secondTermAvn10: undefined, 
                }
            } else if ((this.formType === 'UPDATE' || this.formType === 'VIEW') && this.selectedItem) {
                this.form = {
                    cardSchemeIndex: this.selectedItem.cardSchemeIndex,
                    cardSchemeId: this.selectedItem.cardSchemeId.toString(),
                    eptRecord: this.selectedItem.eptRecord,
                    acquirer: { acquirerId: this.selectedItem.acquirer.acquirerId, acquirerName: this.selectedItem.acquirer.acquirerName },
                    rid: this.selectedItem.rid,
                    capkIndex1: this.selectedItem.capkIndex1,
                    capkFile1: this.selectedItem.capkFile1 == '' ? 'Browse': this.selectedItem.capkFile1,
                    capkExptDate1: this.selectedItem.capkExptDate1,
                    capkIndex2: this.selectedItem.capkIndex2,
                    capkFile2: this.selectedItem.capkFile2 == '' ? 'Browse': this.selectedItem.capkFile2,
                    capkExptDate2: this.selectedItem.capkExptDate2,
                    capkIndex3: this.selectedItem.capkIndex3,
                    capkFile3: this.selectedItem.capkFile3 == '' ? 'Browse': this.selectedItem.capkFile3,
                    capkExptDate3: this.selectedItem.capkExptDate3,
                    capkIndex4: this.selectedItem.capkIndex4,
                    capkFile4: this.selectedItem.capkFile4 == '' ? 'Browse': this.selectedItem.capkFile4,
                    capkExptDate4: this.selectedItem.capkExptDate4,
                    capkIndex5: this.selectedItem.capkIndex5,
                    capkFile5: this.selectedItem.capkFile5 == '' ? 'Browse': this.selectedItem.capkFile5,
                    capkExptDate5: this.selectedItem.capkExptDate5,
                    capkIndex6: this.selectedItem.capkIndex6,
                    capkFile6: this.selectedItem.capkFile6 == '' ? 'Browse': this.selectedItem.capkFile6,
                    capkExptDate6: this.selectedItem.capkExptDate6,
                    capkIndex7: this.selectedItem.capkIndex7,
                    capkFile7: this.selectedItem.capkFile7 == '' ? 'Browse': this.selectedItem.capkFile7,
                    capkExptDate7: this.selectedItem.capkExptDate7,
                    capkIndex8: this.selectedItem.capkIndex8,
                    capkFile8: this.selectedItem.capkFile8 == '' ? 'Browse': this.selectedItem.capkFile8,
                    capkExptDate8: this.selectedItem.capkExptDate8,
                    capkIndex9: this.selectedItem.capkIndex9,
                    capkFile9: this.selectedItem.capkFile9 == '' ? 'Browse': this.selectedItem.capkFile9,
                    capkExptDate9: this.selectedItem.capkExptDate9,
                    capkIndex10: this.selectedItem.capkIndex10,
                    capkFile10: this.selectedItem.capkFile10 == '' ? 'Browse': this.selectedItem.capkFile10,
                    capkExptDate10: this.selectedItem.capkExptDate10,
                    supportedAid1: this.selectedItem.supportedAid1,
                    partialNameFlag1: this.selectedItem.partialNameFlag1,
                    termAvn1: this.selectedItem.termAvn1,
                    secondTermAvn1: this.selectedItem.secondTermAvn1,
                    supportedAid2: this.selectedItem.supportedAid2,
                    partialNameFlag2: this.selectedItem.partialNameFlag2,
                    termAvn2: this.selectedItem.termAvn2,
                    secondTermAvn2: this.selectedItem.secondTermAvn2,
                    supportedAid3: this.selectedItem.supportedAid3,
                    partialNameFlag3: this.selectedItem.partialNameFlag3,
                    termAvn3: this.selectedItem.termAvn3,
                    secondTermAvn3: this.selectedItem.secondTermAvn3,
                    supportedAid4: this.selectedItem.supportedAid4,
                    partialNameFlag4: this.selectedItem.partialNameFlag4,
                    termAvn4: this.selectedItem.termAvn4,
                    secondTermAvn4: this.selectedItem.secondTermAvn4,
                    supportedAid5: this.selectedItem.supportedAid5,
                    partialNameFlag5: this.selectedItem.partialNameFlag5,
                    termAvn5: this.selectedItem.termAvn5,
                    secondTermAvn5: this.selectedItem.secondTermAvn5,
                    supportedAid6: this.selectedItem.supportedAid6,
                    partialNameFlag6: this.selectedItem.partialNameFlag6,
                    termAvn6: this.selectedItem.termAvn6,
                    secondTermAvn6: this.selectedItem.secondTermAvn6,
                    supportedAid7: this.selectedItem.supportedAid7,
                    partialNameFlag7: this.selectedItem.partialNameFlag7,
                    termAvn7: this.selectedItem.termAvn7,
                    secondTermAvn7: this.selectedItem.secondTermAvn7,
                    supportedAid8: this.selectedItem.supportedAid8,
                    partialNameFlag8: this.selectedItem.partialNameFlag8,
                    termAvn8: this.selectedItem.termAvn8,
                    secondTermAvn8: this.selectedItem.secondTermAvn8,
                    supportedAid9: this.selectedItem.supportedAid9,
                    partialNameFlag9: this.selectedItem.partialNameFlag9,
                    termAvn9: this.selectedItem.termAvn9,
                    secondTermAvn9: this.selectedItem.secondTermAvn9,
                    supportedAid10: this.selectedItem.supportedAid10,
                    partialNameFlag10: this.selectedItem.partialNameFlag10,
                    termAvn10: this.selectedItem.termAvn10,
                    secondTermAvn10: this.selectedItem.secondTermAvn10, 
                }
                console.log(this.form)
            }
        }
    }
}
</script>