<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable persistent max-width="1180px">
        <v-card ref="formCard">
            <v-dialog v-model="dialogVisible" persistent max-width="400px">
                <v-card class="pa-2" color="primary">
                    <v-card-title style="color: white;" class="d-flex justify-center">
                        <span>Any changes made will be discarded,</span>
                        <span>would you like to continue?</span>
                    </v-card-title>
                    <v-card-actions class="d-flex justify-center">
                    <v-btn dark color="primary darken-1" @click="closeDialogPrompt">Yes</v-btn>
                    <v-btn dark color="primary darken-1" @click="dialogVisible = false">No</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <card-scheme-form ref="cardSchemeForm" :selected-item="selectedCardScheme" :is-show="showCSFormDialog" :acquirers="acquirers" :users="users" :form-type="cardSchemeFormType" v-on:close-dialog="closeDialog" />
            <emv-form ref="emvForm" :selected-item="selectedEmv" :is-show="showEmvFormDialog" :users="users" :form-type="emvFormType" v-on:close-dialog="closeDialog"/>
            <v-card-title>
                <span class="headline ma-5" v-if="formType === 'CREATED'">Create New Terminal</span>
                <span class="headline ma-5" v-else-if="formType === 'UPDATE'">Update Terminal</span>
                <span class="headline ma-5" v-else-if="formType === 'VIEW'">View Terminal</span>
                <!-- <span class="headline" v-if="formType === 'UPDATE'">Update Terminal</span>
                <span class="headline" v-else>Create New Terminal</span> -->
                <v-card-text>
                <v-stepper v-model="e1" class="mx-5 elevation-0">
                    <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1"> Terminal </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" :step="dynamicStepBatch"> Batch </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 3" :step="dynamicStepCS"> Card Scheme </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 4" :step="dynamicStepCurrency"> Currency </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 5" :step="dynamicStepEmvParameter"> Emv Parameter </v-stepper-step>
                    </v-stepper-header>
                </v-stepper>
                </v-card-text>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-stepper v-model="e1" class="elevation-0">
                        <v-stepper-items>
                            <!-- FORM 1 -->
                            <v-stepper-content step="1">
                                <v-form ref="formEntryOne" :disabled="formType === 'VIEW'">
                                    <v-container grid-list-md>
                                        <v-layout row wrap>
                                            <v-flex xs12 sm6>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Terminal ID <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : ''" maxLength="8" v-numeric-input :readonly="formType === 'UPDATE'" v-model="form.terminalId" required :rules="[rules.requiredField]" data-cy="form-terminal-id"></v-text-field>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Serial Number <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : ''" v-model="form.serialNum" required :rules="[rules.requiredField]" data-cy="form-serial-number"></v-text-field>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Model <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Model'" v-model="form.model" required :rules="[rules.requiredField]" data-cy="form-model"></v-text-field>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Type of POS <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select 
                                                            :items="typeOfPosList" 
                                                            v-model="form.type" 
                                                            :label="formType === 'VIEW' ? '' : 'Select Type of POS'" 
                                                            required :rules="[rules.requiredSelection]" 
                                                            @change="getTypeOfPos" 
                                                            data-cy="form-type"
                                                            ></v-select>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Device Type <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-autocomplete 
                                                        clearable
                                                        chips
                                                        :items="deviceTypeList" 
                                                        item-text="name" 
                                                        item-value="code" 
                                                        :label="formType === 'VIEW' ? '' : 'Select Device Type'" 
                                                        v-model="form.deviceType" 
                                                        return-object 
                                                        required :rules="[rules.requiredField]"
                                                        data-cy="form-device-type"
                                                        ></v-autocomplete>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Merchant Association <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-autocomplete
                                                        v-model="form.merchantAssociation"
                                                        clearable
                                                        chips
                                                        item-text="name"
                                                        item-value="merchantId"
                                                        :items="merchantAssociationList"
                                                        data-cy="form-merchantAssociation"
                                                        return-object
                                                        :rules="[rules.requiredSelection]"
                                                        multiple
                                                        ></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>POS Terminal Entry Capability <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select :items="posTerminalEntryCapabilityList" 
                                                        v-model="form.posTerminalEntryCapability" 
                                                        :label="formType === 'VIEW' ? '' : 'Select POS Terminal Entry Capability'" 
                                                        @change="getPosTerminalEntryCapability" 
                                                        required :rules="[rules.requiredField]"
                                                        data-cy="form-pos-terminal-entry-capability"></v-select>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>POS Terminal List <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select :items="posterminalTypeList" 
                                                        v-model="form.posterminalType" 
                                                        :label="formType === 'VIEW' ? '' : 'Select POS Terminal Type'" 
                                                        @change="getPosTerminalType" 
                                                        required :rules="[rules.requiredField]"
                                                        data-cy="form-posterminal-type"></v-select>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>User</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-autocomplete clearable chips :items="userList" item-text="username" item-value="username" :label="formType === 'VIEW' ? '' : 'Select a User'" v-model="form.user" return-object data-cy="form-username">
                                                        </v-autocomplete>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Network</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Network'" v-model="form.network" data-cy="form-network"></v-text-field>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Location</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select :items="bancnetLocationList" v-model="form.location" :label="formType === 'VIEW' ? '' : 'Select Location'" @change="getLocation" data-cy="form-location"></v-select>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Pan/Date Entry Mode</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select :items="pandateEntryModeList" v-model="form.pandateEntryMode" :label="formType === 'VIEW' ? '' : 'SelectPan/Date Entry Mode'" @change="getPandateEntryMode" data-cy="form-pandate-entrymode"></v-select>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Bancnet Address</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Bancnet Address'" v-model="form.bancnetAddress" data-cy="form-bancnet-address"> </v-text-field>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Bancnet Merchant Settlement Bank Code</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Bancnet Merchant Settlement Bank Code'" v-model="form.bancnetMerchantSettlementBankCode" data-cy="form-bancnet-merchantsettlement-bank-code"> </v-text-field>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <!-- <span>Bancnet Terminal Owner</span> -->
                                                        <span>Bancnet Merchant ID</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Bancnet Merchant ID'" v-model="form.bancnetTerminalOwner" data-cy="form-bancnet-terminalowner"> </v-text-field></v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <!-- <span>Bancnet Terminal Code</span> -->
                                                        <span>Bancnet Terminal ID</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Bancnet Terminal ID'" v-model="form.bancnetTerminalCode" data-cy="form-bancnet-terminal-code"> </v-text-field>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Bancnet Fixed Fee</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Bancnet Fixed Fee'" v-model="form.bancnetFixedFee" @keypress="isNumber($event)" data-cy="form-bancnet-fixed-fee"> </v-text-field>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Bancnet Convenience Fee</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Bancnet Convenience Fee'" v-model="form.bancnetConvenienceFee" @keypress="isNumber($event)" data-cy="form-bancnet-convenience-fee"> </v-text-field></v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Maximum Amount</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Maximum Amount'" v-model="form.maximumAmount" @keypress="isNumber($event)" data-cy="form-maximum-amount"> </v-text-field>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Pin Entry Capability</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select :items="pinEntryCapabilityList" v-model="form.pinEntryCapability" :label="formType === 'VIEW' ? '' : 'Select Pin Entry Capability'" @change="getPinEntryCapability" data-cy="form-pin-entry-capability"></v-select>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>POS Condition Code</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select :items="posConditionCodeList" v-model="form.posConditionCode" :label="formType === 'VIEW' ? '' : 'Select POS Condition Code'" @change="getPosConditionCode" data-cy="form-pos-condition-code"></v-select>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Sim Network</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Sim Network'" v-model="form.simNetwork" data-cy="form-sim-network"> </v-text-field>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Sim Card Number</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Sim Card Number'" v-model="form.simCardNumber" data-cy="form-simcard-number"> </v-text-field>
                                                    </v-col>               
                                                </v-row>
                                            </v-flex>
                                            <v-flex xs12 sm6>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Terminal Password <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field 
                                                            @keydown.space.prevent
                                                            :label="formType === 'VIEW' ? '' : 'Terminal Password'"
                                                            v-numeric-input
                                                            maxLength="6" 
                                                            type="password"
                                                            v-model="form.terminalPassword" 
                                                            data-cy="form-terminal-password"
                                                            required :rules="[rules.terminalPass, rules.requiredField]"
                                                            @click="clearIfInitialValue">
                                                        </v-text-field>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Terminal MAC Address <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field  v-model="form.terminalMacAddress" :label="formType === 'VIEW' ? '' : 'Terminal Mac Address'"  required :rules="[rules.requiredField]" data-cy="form-terminal-mac-address"></v-text-field>
                                                    </v-col>            
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Terminal Mode <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select :items="terminalModeList" v-model="form.terminalMode" :label="formType === 'VIEW' ? '' : 'Select Terminal Mode'" required :rules="[rules.requiredField]" @change="getTerminalMode" data-cy="form-terminal-mode"></v-select>
                                                    </v-col>            
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Terminal Port Number <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Terminal Port Number'" maxLength="5"  required :rules="[rules.requiredField]" v-numeric-input v-model="form.terminalPortNumber" data-cy="form-terminal-port-number"></v-text-field>
                                                    </v-col>            
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Date Installed</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-menu v-model="showDateInstalledMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="form-date-display-installed">
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field :label="formType === 'VIEW' ? '' : 'mm/dd/yyyy'" append-icon="mdi-calendar" readonly :value="displayDateInstalled" v-on="on"></v-text-field>
                                                            </template>
                                                            <v-date-picker locale="en-in" v-model="form.dateInstalled" no-title @input="showDateInstalledMenu = false" ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>            
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Installed By</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Installed By'" v-model="form.installedBy" data-cy="form-installed-by"> </v-text-field>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>IP Address</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Ip Address'" maxLength="15" v-model="form.ipAddress" data-cy="form-ip-address"></v-text-field>
                                                    </v-col>            
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Card Network Used</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Card Network Used'" v-model="form.cardNetworkUsed" data-cy="form-card-network-used"></v-text-field></v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Motopayment Indicator</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select :items="MOTOPaymentIndicatorList" v-model="form.motopaymentIndicator" :label="formType === 'VIEW' ? '' : 'Select Motopayment Indicator'" @change="getMotopaymentIndicator" data-cy="form-moto-payment-indicator"></v-select></v-col>            
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Unattended Acceptance Terminal Indicator</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select :items="unattendedAcceptanceTerminalIndicatorList" v-model="form.unattendedAcceptanceTerminalIndicator" :label="formType === 'VIEW' ? '' : 'Select Unattended Acceptance Terminal Indicator'" @change="getUnattendedAcceptanceTerminalIndicator" data-cy="form-unattended-acceptance-terminal-indicator"></v-select>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Card Holderidmethod</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select :items="cardHolderIDMethodList" v-model="form.cardHolderidmethod" :label="formType === 'VIEW' ? '' : 'Card Holderidmethod'" @change="getCardHolderidmethod" data-cy="form-card-holder-id-method"></v-select>
                                                    </v-col>            
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Service Development Field</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select :items="serviceDevelopmentFieldList" v-model="form.serviceDevelopmentField" :label="formType === 'VIEW' ? '' : 'Select Service Development Field'" @change="getServiceDevelopmentField" data-cy="form-service-development-field"></v-select>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Auto Settlement Time</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-menu v-model="showASTMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="form-auto-settlement-time">
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field :label="formType === 'VIEW' ? '' : 'Auto Settlement Time'" append-icon="mdi-clock" readonly :value="displayAST" v-on="on"></v-text-field>
                                                            </template>
                                                            <v-time-picker v-model="form.autoSettlementTime" format="ampm" @input="showASTMenu = false"></v-time-picker>
                                                        </v-menu>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" v-if="form.terminalMode !== 'Integrated'" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Auto Settlement Flag</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-switch v-model="form.autoSettlementFlag" data-cy="form-ASF-switch-button"></v-switch>
                                                    </v-col>            
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Print Sale Receipt</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-switch v-model="form.printSaleReceipt" data-cy="form-PSR-switch-button"></v-switch>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Enable Status</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-switch v-model="form.isEnabled" data-cy="form-switch-button"></v-switch>
                                                    </v-col>            
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>EMV Contact</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-switch v-model="form.emvContact" data-cy="form-switch-button"></v-switch>
                                                    </v-col>            
                                                </v-row>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-form>
                            </v-stepper-content>
                            <!-- FORM 2 -->
                            <v-stepper-content :step="dynamicStepBatch" >
                                <v-form ref="formEntryTwo" :disabled="formType === 'VIEW'">
                                    <v-container grid-list-md>
                                        <v-layout row wrap>
                                            <v-flex xs12 sm6>
                                                <v-row class="align-center" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span v-if="batchIndexChecker" >Batch Index <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field v-if="batchIndexChecker" :readonly="true" :label="formType === 'VIEW' ? '' : ''" maxLength="2" v-numeric-input :rules="[rules.requiredField]"  v-model="form.batchIndex" data-cy="form-batch-index"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="align-center" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Batch Limit <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : ''" maxLength="6" v-numeric-input :rules="[rules.requiredField]" v-model="form.batchLimit" data-cy="form-batch-limit"></v-text-field>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Batch Number <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'Batch Number'" maxLength="6" v-numeric-input :rules="[rules.requiredField]" v-model="form.batchNumber" data-cy="form-batch-number"></v-text-field>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>STAN <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : 'STAN'" maxLength="6" v-numeric-input :rules="[rules.requiredField]" v-model="form.stan" data-cy="form-stan"></v-text-field>
                                                    </v-col>               
                                                </v-row>
                                            </v-flex>
                                            <v-flex xs12 sm6></v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-form>
                            </v-stepper-content>
                            <!-- FORM 3 -->
                            <v-stepper-content :step="dynamicStepCS" >
                                <v-form ref="formEntryThree" :disabled="formType === 'VIEW'">
                                    <v-container grid-list-md>
                                        <v-layout row wrap>
                                            <v-flex xs12 sm6>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Select Scheme <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-autocomplete
                                                            v-model="form.cardScheme"
                                                            :items="cardSchemeList"
                                                            item-text="displayText"
                                                            item-value="cardSchemeId"
                                                            :label="formType === 'VIEW' ? '' : 'Select Card Scheme ID'"
                                                            data-cy="form-card-scheme-id-list"
                                                            clearable
                                                            :rules="[rules.checkIfCSNotEmpty]"
                                                            return-object
                                                        ></v-autocomplete>
                                                    </v-col>   
                                                    <v-col cols="1">
                                                        <v-icon color="black" @click="addItemCS" :disabled="this.formType === 'VIEW'">mdi-plus-thick</v-icon>
                                                    </v-col>             
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <template>
                                                            <div>
                                                                <v-data-table :headers="headersCS" :items="itemsCS" class="elevation-4" hide-default-footer>
                                                                    <template #item.action="{ item }">
                                                                        <v-icon @click.stop="viewCS('VIEW', item)" color="black" v-if="item.cardSchemeId != ''">mdi-eye</v-icon>
                                                                        <v-icon @click.stop="deleteItem(item, 'cs')" color="black" v-if="item.cardSchemeId != ''">mdi-delete</v-icon>
                                                                    </template>
                                                                </v-data-table>
                                                            </div>
                                                        </template>
                                                    </v-col>               
                                                </v-row>
                                            </v-flex>
                                            <v-flex xs12 sm6></v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-form>
                            </v-stepper-content>
                            <!-- FORM 4 -->
                            <v-stepper-content :step="dynamicStepCurrency" >
                                <v-form ref="formEntryFour" :disabled="formType === 'VIEW'">
                                    <v-container grid-list-md>
                                        <v-layout row wrap>
                                            <v-flex xs12 sm6>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Country <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select
                                                            v-model="form.currency"
                                                            :items="currencyList"
                                                            item-text="country"
                                                            item-value="country"
                                                            :label="formType === 'VIEW' ? '' : 'Select Country'"
                                                            data-cy="form-currency-list"
                                                            return-object
                                                            required :rules="[rules.requiredField]" 
                                                            @change="handleCurrencyChange"
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Alpha Code</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : ''" v-model="form.currency.code" :disabled="true" data-cy="form-alpha-code"></v-text-field>
                                                    </v-col>               
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Currency Name</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : ''" v-model="form.currency.name" :disabled=true data-cy="form-currency-name"></v-text-field>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Numeric Code</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : ''" v-model="form.currency.numCode" :disabled=true data-cy="form-numeric-code"></v-text-field>
                                                    </v-col>              
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Symbol</span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field :label="formType === 'VIEW' ? '' : ''" v-model="form.currency.symbol" :disabled=true data-cy="form-symbol"></v-text-field>
                                                    </v-col>               
                                                </v-row>
                                            </v-flex>
                                            <v-flex xs12 sm6></v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-form>
                            </v-stepper-content>
                            <!-- FORM 5 -->
                            <v-stepper-content :step="dynamicStepEmvParameter" >
                                <v-form ref="formEntryFive" :disabled="formType === 'VIEW'">
                                    <v-container grid-list-md>
                                        <v-layout row wrap>
                                            <v-flex xs12 sm6>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <span>Select Scheme Reference <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-autocomplete
                                                            v-model="form.emv"
                                                            :items="emvList"
                                                            item-text="schemeReference"
                                                            item-value="schemeReference"
                                                            :label="formType === 'VIEW' ? '' : 'Select Scheme Reference'"
                                                            data-cy="form-emv-parameter-index"
                                                            clearable
                                                            :rules="[rules.checkIfEmvNotEmpty]"
                                                            return-object
                                                        ></v-autocomplete>
                                                    </v-col>  
                                                    <v-col cols="1">
                                                        <v-icon color="black" @click="addItemEmv" :disabled="this.formType === 'VIEW'">mdi-plus-thick</v-icon>
                                                    </v-col>           
                                                </v-row>
                                                <v-row class="align-center custom-row-height" no-gutters>
                                                    <v-col class="ml-5">
                                                        <v-data-table :headers="headersEmv" :items="itemsEmv" class="elevation-4" hide-default-footer>
                                                            <template #item.action="{ item }">
                                                                <v-icon @click.stop="viewEmv('VIEW', item)" color="black" v-if="item.schemeReference != ''">mdi-eye</v-icon>
                                                                <v-icon @click.stop="deleteItem(item, 'emv')" color="black" v-if="item.schemeReference != ''">mdi-delete</v-icon>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>               
                                                </v-row>
                                            </v-flex>
                                            <v-flex xs12 sm6></v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-form>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-form>
                <!-- <small v-if="formType != 'VIEW'">*indicates required field</small> -->
            </v-card-text>
            <v-card-actions class="ma-5">
                <small v-if="formType != 'VIEW'">*indicates required field</small>
                <v-spacer></v-spacer>
                <v-btn color="primary" :style="{'min-width': $vuetify.breakpoint.smAndUp ? '100px' : 'auto'}" v-if="e1 != 1" @click="back(e1--)">Back</v-btn>
                <v-btn color="primary" :style="{'min-width': $vuetify.breakpoint.smAndUp ? '100px' : 'auto'}" v-on:click="close">Cancel</v-btn>
                <v-btn color="primary" :style="{'min-width': $vuetify.breakpoint.smAndUp ? '100px' : 'auto'}" v-if="e1 != 5" @click="next(e1++)">Continue</v-btn>
                <v-btn color="primary" :style="{'min-width': $vuetify.breakpoint.smAndUp ? '100px' : 'auto'}" v-if="formType != 'VIEW' && e1 === 5" @click="save">Save</v-btn>          
                <!-- <v-btn color="blue darken-1" text v-on:click="save">Save</v-btn> -->
            </v-card-actions>
            <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" v-if="formType != 'VIEW'" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions> -->
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
import CardSchemeForm from '@/views/components/CardSchemeForm.vue'
import emvForm from '@/views/components/emvForm.vue'
export default {
    components: {
        CardSchemeForm,
        emvForm,   
    },
    directives: {
        'numeric-input': {
        inserted(el) {
            el.addEventListener('input', (event) => {
                const value = event.target.value;
                event.target.value = value.replace(/\D/g, ''); // Allow only numeric characters
            });
        }
        }
    },
    props: {
        isShow: Boolean,
        isNotPaymentAgent: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        // branches: Array,
        deviceTypes: Array,
        currencies: Array,
        users: Array,
        merchants: Array,
        cardSchemes: Array,
        acquirers: Array,   
        emvs: Array,
        // paymentAgents: Array,
    },
    computed: {
        // checkIfCSNotEmpty() {
        //     return !this.itemsCS.every(item => 'id' in item) || "Required at least one selection"
        // },
        // checkIfEmvNotEmpty() {
        //     return !this.itemsEmv.every(item => 'id' in item) || "Required at least one selection"
        // },
        dynamicStepBatch() {
        // return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 2 : -1
            return 2
        },
        dynamicStepCS() {
            // return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 3 : 2
            return 3
        },
        dynamicStepCurrency() {
            // return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 4 : 3
            return 4
        },
        dynamicStepEmvParameter() {
            // return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 5 : 4 
            return 5
        },
        populatedForm() {
            var merchList = this.form.merchantAssociation.map(item => ({
                merchantId: item.merchantId, 
                // name: item.name,
            }));
            var autoFlag = this.form.autoSettlementFlag
            if(this.form.terminalMode == "Integrated") {
                autoFlag = true
            } 
        
            console.log("MERCH LIST", merchList)
            return {
                
                terminalId: this.form.terminalId,
                serialNum: this.form.serialNum,
                model: this.form.model,
                type: this.form.type,
                // merchant: this.form.merchant,
                // branch: this.form.branch,
                deviceType: this.form.deviceType,
                currency: this.form.currency,
                cardScheme: this.itemsCS,
                emv: this.itemsEmv,
                // paymentAgent: this.form.paymentAgent,
                user: this.form.user,
                network: this.form.network,
                location: this.form.location,
                pandateEntryMode: this.form.pandateEntryMode,
                posterminalType: this.form.posterminalType,
                bancnetAddress: this.form.bancnetAddress,
                bancnetFixedFee: this.form.bancnetFixedFee,
                bancnetMerchantSettlementBankCode: this.form.bancnetMerchantSettlementBankCode,
                bancnetTerminalOwner: this.form.bancnetTerminalOwner,
                bancnetTerminalCode: this.form.bancnetTerminalCode,
                maximumAmount: this.form.maximumAmount,
                pinEntryCapability: this.form.pinEntryCapability,
                posTerminalEntryCapability: this.posEntryCapabilitiesOptions(this.form.posTerminalEntryCapability),
                posConditionCode: this.form.posConditionCode,
                bancnetConvenienceFee: this.form.bancnetConvenienceFee,
                simNetwork: this.form.simNetwork,
                simCardNumber: this.form.simCardNumber,
                dateInstalled: this.form.dateInstalled,
                installedBy: this.form.installedBy,
                ipAddress: this.form.ipAddress,
                // status: this.form.status,
                cardNetworkUsed: this.form.cardNetworkUsed,
                motopaymentIndicator: this.form.motopaymentIndicator,
                unattendedAcceptanceTerminalIndicator: this.form.unattendedAcceptanceTerminalIndicator,
                cardHolderidmethod: this.form.cardHolderidmethod,
                serviceDevelopmentField: this.form.serviceDevelopmentField,
                isEnabled: this.form.isEnabled,
                emvContact: this.form.emvContact,
                terminalPassword: this.form.terminalPassword, 
                autoSettlementFlag: autoFlag, // this.form.autoSettlementFlag,
                autoSettlementTime: this.form.autoSettlementTime, 
                terminalPortNumber: this.form.terminalPortNumber, 
                printSaleReceipt: this.form.printSaleReceipt, 
                terminalMode: this.terminalModeOptions(this.form.terminalMode), 
                terminalMacAddress: this.form.terminalMacAddress,
                merchantAssociation: merchList,
                batchIndex: this.form.batchIndex,
                batchLimit: this.form.batchLimit,
                batchNumber: this.form.batchNumber,
                stan: this.form.stan,
                formType: this.formType
            }
        },
        displayDateInstalled() {
            return this.form.dateInstalled
        },
        displayAST() {
            return this.form.autoSettlementTime
        }
    },
    watch: {
        // itemsCS: {
        //     handler: 'updateDisabledOptions',
        //     deep: true,
        // },
        'form.password'(newValue) {
            this.batchIndexChecker = newValue != undefined;
            console.log(newValue)
        },
        'form.batchIndex'(newValue) {
            this.batchIndexChecker = newValue != undefined;
            console.log(newValue)
        },
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        // branches: {
        //     handler(value) {
        //         this.branchList = value
        //     }
        // },
        deviceTypes: {
            handler(value) {
                this.deviceTypeList = value
            }
        },
        currencies: {
            handler(value) {
                this.currencyList = value.filter(item => item.symbol && item.country);
            }
        },
        // paymentAgents: {
        //     handler(value) {
        //         this.paymentAgentList = value
        //     }
        // },
        users: {
            handler(value) {
                this.userList = value
            }
        },
        merchants: {
            handler(value) {
                this.merchantAssociationList = value
                console.log("terminalform merchants", value)
            }
        },
        cardSchemes: {
            handler(value) {
                this.cardSchemeList = value
            }
        },
        acquirers: {
            handler(value) {
                this.acquirerList = value
            }
        },
        emvs: {
            handler(value) {
                this.emvList = value
            }
        },
        deep: true,
    },
    data() {
        return {
            selectedCardSchemes: [],
            dialogVisible: false,
            batchIndexChecker: false,
            emvFormType: '',
            cardSchemeFormType: '',
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            // branchList: this.branches ?? [],
            deviceTypeList: this.deviceTypes ?? [],
            currencyList: this.currencies ?? [],
            // paymentAgentList: this.paymentAgents ?? [],
            userList: this.users ?? [],
            // merchantList: this.merchants ?? [],
            cardSchemeList: this.cardSchemes ?? [],
            acquirerList: this.acquirers ?? [],
            emvList: this.emvs ?? [],
            showDateInstalledMenu: false,
            showASTMenu: false,
            pandateEntryModeList: ['', '00', '01', '02', '03', '04', '05', '06', '07', '90', '91', '95'],
            pinEntryCapabilityList: ['', '0', '1', '2', '8', '9'],
            posConditionCodeList: ['', '00', '01', '02', '03', '05', '06', '08', '10', '51', '59', '71'],
            posterminalTypeList: ['0'], // 
            posTerminalEntryCapabilityList: ['Contact & Contactless', 'Contactless'], // 3- QR CODE, 5 - for contact and contacless, 8 - contactless only
            bancnetLocationList: ['', 'Luzon', 'Visayas', 'Mindanao'],
            MOTOPaymentIndicatorList: ['', '00', '01', '02', '03', '04', '05', '06', '07', '08', '09'],
            cardHolderIDMethodList: ['', '0', '1', '2', '3', '4'],
            serviceDevelopmentFieldList: ['', '0', '1', '2', '5', '6', '7'],
            unattendedAcceptanceTerminalIndicatorList: ['', '1', '2', '3', '4'],
            typeOfPosList: ['', 'REGULAR/SALE', 'CASHOUT'],
            terminalModeList: ['Standalone', 'Integrated', 'Standalone Demo', 'Integrated Demo'],
            merchantAssociationList: this.merchants ?? [],
            // posStatusList: ['IN_SERVICE', 'ONLINE', 'OFFLINE', 'CLOSED', 'FOR_REPLACEMENT', 'REPLACED', 'FOR_TERMINATION', 'RETURNED', 'DEACTIVATED', 'OTHERS'],
            form: {
                branch: {},
                deviceType: {},
                currency: {},
                cardScheme: {},
                emv: {},
                paymentAgent: {},
                user: {},
                merchant: {},
                itemsCS: {},
                itemsEmv: {}
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                terminalPass: v => {
                    // Check if the value is numeric or "*****"
                    if (v === "*****" || /^\d+$/.test(v)) {
                        return true;
                    }
                    return "Please enter a numeric value";
                },
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
                checkIfCSNotEmpty: v => !this.itemsCS.every(item => 'id' in item) || "Required at least one selection",
                checkIfEmvNotEmpty: v => !this.itemsEmv.every(item => 'id' in item) || "Required at least one selection"
            },
            e1:1,
            isFormComplete: false,
            // for adding data on Card Scheme Table
            headersCS: [
                { text: 'Card Scheme Index', value: 'cardSchemeIndex' },
                { text: 'Card Scheme ID', value: 'displayText' },
                { text: 'Action', value: 'action' },
            ],
            itemsCS: [
                { id: 1, cardSchemeIndex: '', cardSchemeId: ''},
                { id: 2, cardSchemeIndex: '', cardSchemeId: ''},
            ],
            itemCS: 0, // Initial ID for new items

            // for adding data on Card Scheme Table
            headersEmv: [
                { text: 'EMV Parameter Index', value: 'emvId' },
                { text: 'Scheme Reference', value: 'schemeReference' },
                { text: 'Action', value: 'action' },
            ],
            itemsEmv: [
                { 
                    id: 1, 
                    emvId: '', 
                    schemeReference: '',
                },
                { 
                    id: 2, 
                    emvId: '', 
                    schemeReference: '',
                },
            ],
            itemEmv: 0, // Initial ID for new items
            showFormCSDialog: false,
            selectedCardScheme: {},
            showCSFormDialog: false,
            showFormEmvDialog: false,
            selectedEmv: {},
            showEmvFormDialog: false,
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        terminalModeOptions(mode) {
            // ['Standalone', 'Integrated', 'Standalone Demo', 'Integrated Demo']
            switch(mode) {
                case "Standalone":
                    return "0"
                case "Integrated":
                    return "1"
                case "Standalone Demo":
                    return "2"
                case "Integrated Demo":
                    return "3" 
                default:
                    return "2"                
            }
        },
        posEntryCapabilitiesOptions(option) {
            // ['Standalone', 'Integrated', 'Standalone Demo', 'Integrated Demo']
            switch(option) {
                case "Contact & Contactless":
                    return "5"
                case "Contactless":
                    return "8"
                case "5":
                    return "Contact & Contactless"
                case "8":
                    return "Contactless"
                default:
                    return ""                
            }
        },
        // updateDisabledOptions() {
        //     const hasCardSchemeId1 = this.itemsCS.some(item => item.cardSchemeId === 1);
        //     const hasCardSchemeId2 = this.itemsCS.some(item => item.cardSchemeId === 2);

        //     this.cardSchemeList.forEach(option => {
        //         if ((hasCardSchemeId1 && option.cardSchemeId === 2) ||
        //             (hasCardSchemeId2 && option.cardSchemeId === 1)) {
        //             option.disabled = true;
        //         } else {
        //             option.disabled = false;
        //         }
        //     });
        // },
        clearIfInitialValue() {
            if (this.form.terminalPassword === "*****") {
                this.form.terminalPassword = null;
            }
        },
        checkItemIfExisting(id, items) {
            for (const item of items) {
                if (item.cardSchemeId === id || item.schemeReference === id) {
                    console.log("TRUE")
                    return true; // Found a matching
                }
            }
            return false; // No matching found
        },
        
        // add items on Card Scheme
        addItemCS() {
            if(!this.checkItemIfExisting(this.form.cardScheme.cardSchemeId, this.itemsCS)) {
                if (this.itemCS < 2) {
                    this.$set(this.itemsCS, this.itemCS++, 
                    { 
                        acquirer: this.form.cardScheme.acquirer,
                        cardSchemeIndex: this.form.cardScheme.cardSchemeIndex, 
                        displayText: this.form.cardScheme.displayText,
                        cardSchemeId: this.form.cardScheme.cardSchemeId,
                        eptRecord: this.form.cardScheme.eptRecord,
                        rid: this.form.cardScheme.rid,
                        capkIndex1: this.form.cardScheme.capkIndex1,
                        capkFile1: this.form.cardScheme.capkFile1,
                        capkExptDate1: this.form.cardScheme.capkExptDate1,
                        capkIndex2: this.form.cardScheme.capkIndex2,
                        capkFile2: this.form.cardScheme.capkFile2,
                        capkExptDate2: this.form.cardScheme.capkExptDate2,
                        capkIndex3: this.form.cardScheme.capkIndex3,
                        capkFile3: this.form.cardScheme.capkFile3,
                        capkExptDate3: this.form.cardScheme.capkExptDate3,
                        capkIndex4: this.form.cardScheme.capkIndex4,
                        capkFile4: this.form.cardScheme.capkFile4,
                        capkExptDate4: this.form.cardScheme.capkExptDate4,
                        capkIndex5: this.form.cardScheme.capkIndex5,
                        capkFile5: this.form.cardScheme.capkFile5,
                        capkExptDate5: this.form.cardScheme.capkExptDate5,
                        capkIndex6: this.form.cardScheme.capkIndex6,
                        capkFile6: this.form.cardScheme.capkFile6,
                        capkExptDate6: this.form.cardScheme.capkExptDate6,
                        capkIndex7: this.form.cardScheme.capkIndex7,
                        capkFile7: this.form.cardScheme.capkFile7,
                        capkExptDate7: this.form.cardScheme.capkExptDate7,
                        capkIndex8: this.form.cardScheme.capkIndex8,
                        capkFile8: this.form.cardScheme.capkFile8,
                        capkExptDate8: this.form.cardScheme.capkExptDate8,
                        capkIndex9: this.form.cardScheme.capkIndex9,
                        capkFile9: this.form.cardScheme.capkFile9,
                        capkExptDate9: this.form.cardScheme.capkExptDate9,
                        capkIndex10: this.form.cardScheme.capkIndex10,
                        capkFile10: this.form.cardScheme.capkFile10,
                        capkExptDate10: this.form.cardScheme.capkExptDate10,
                        supportedAid1: this.form.cardScheme.supportedAid1,
                        partialNameFlag1: this.form.cardScheme.partialNameFlag1,
                        termAvn1: this.form.cardScheme.termAvn1,
                        secondTermAvn1: this.form.cardScheme.secondTermAvn1,
                        supportedAid2: this.form.cardScheme.supportedAid2,
                        partialNameFlag2: this.form.cardScheme.partialNameFlag2,
                        termAvn2: this.form.cardScheme.termAvn2,
                        secondTermAvn2: this.form.cardScheme.secondTermAvn2,
                        supportedAid3: this.form.cardScheme.supportedAid3,
                        partialNameFlag3: this.form.cardScheme.partialNameFlag3,
                        termAvn3: this.form.cardScheme.termAvn3,
                        secondTermAvn3: this.form.cardScheme.secondTermAvn3,
                        supportedAid4: this.form.cardScheme.supportedAid4,
                        partialNameFlag4: this.form.cardScheme.partialNameFlag4,
                        termAvn4: this.form.cardScheme.termAvn4,
                        secondTermAvn4: this.form.cardScheme.secondTermAvn4,
                        supportedAid5: this.form.cardScheme.supportedAid5,
                        partialNameFlag5: this.form.cardScheme.partialNameFlag5,
                        termAvn5: this.form.cardScheme.termAvn5,
                        secondTermAvn5: this.form.cardScheme.secondTermAvn5,
                        supportedAid6: this.form.cardScheme.supportedAid6,
                        partialNameFlag6: this.form.cardScheme.partialNameFlag6,
                        termAvn6: this.form.cardScheme.termAvn6,
                        secondTermAvn6: this.form.cardScheme.secondTermAvn6,
                        supportedAid7: this.form.cardScheme.supportedAid7,
                        partialNameFlag7: this.form.cardScheme.partialNameFlag7,
                        termAvn7: this.form.cardScheme.termAvn7,
                        secondTermAvn7: this.form.cardScheme.secondTermAvn7,
                        supportedAid8: this.form.cardScheme.supportedAid8,
                        partialNameFlag8: this.form.cardScheme.partialNameFlag8,
                        termAvn8: this.form.cardScheme.termAvn8,
                        secondTermAvn8: this.form.cardScheme.secondTermAvn8,
                        supportedAid9: this.form.cardScheme.supportedAid9,
                        partialNameFlag9: this.form.cardScheme.partialNameFlag9,
                        termAvn9: this.form.cardScheme.termAvn9,
                        secondTermAvn9: this.form.cardScheme.secondTermAvn9,
                        supportedAid10: this.form.cardScheme.supportedAid10,
                        partialNameFlag10: this.form.cardScheme.partialNameFlag10,
                        termAvn10: this.form.cardScheme.termAvn10,
                        secondTermAvn10: this.form.cardScheme.secondTermAvn10, 
                    })
                } else {
                    ++this.itemCS
                    this.itemsCS.push(
                        { 
                            acquirer: this.form.cardScheme.acquirer,
                            cardSchemeIndex: this.form.cardScheme.cardSchemeIndex, 
                            displayText: this.form.cardScheme.displayText,
                            cardSchemeId: this.form.cardScheme.cardSchemeId,
                            eptRecord: this.form.cardScheme.eptRecord,
                            rid: this.form.cardScheme.rid,
                            capkIndex1: this.form.cardScheme.capkIndex1,
                            capkFile1: this.form.cardScheme.capkFile1,
                            capkExptDate1: this.form.cardScheme.capkExptDate1,
                            capkIndex2: this.form.cardScheme.capkIndex2,
                            capkFile2: this.form.cardScheme.capkFile2,
                            capkExptDate2: this.form.cardScheme.capkExptDate2,
                            capkIndex3: this.form.cardScheme.capkIndex3,
                            capkFile3: this.form.cardScheme.capkFile3,
                            capkExptDate3: this.form.cardScheme.capkExptDate3,
                            capkIndex4: this.form.cardScheme.capkIndex4,
                            capkFile4: this.form.cardScheme.capkFile4,
                            capkExptDate4: this.form.cardScheme.capkExptDate4,
                            capkIndex5: this.form.cardScheme.capkIndex5,
                            capkFile5: this.form.cardScheme.capkFile5,
                            capkExptDate5: this.form.cardScheme.capkExptDate5,
                            capkIndex6: this.form.cardScheme.capkIndex6,
                            capkFile6: this.form.cardScheme.capkFile6,
                            capkExptDate6: this.form.cardScheme.capkExptDate6,
                            capkIndex7: this.form.cardScheme.capkIndex7,
                            capkFile7: this.form.cardScheme.capkFile7,
                            capkExptDate7: this.form.cardScheme.capkExptDate7,
                            capkIndex8: this.form.cardScheme.capkIndex8,
                            capkFile8: this.form.cardScheme.capkFile8,
                            capkExptDate8: this.form.cardScheme.capkExptDate8,
                            capkIndex9: this.form.cardScheme.capkIndex9,
                            capkFile9: this.form.cardScheme.capkFile9,
                            capkExptDate9: this.form.cardScheme.capkExptDate9,
                            capkIndex10: this.form.cardScheme.capkIndex10,
                            capkFile10: this.form.cardScheme.capkFile10,
                            capkExptDate10: this.form.cardScheme.capkExptDate10,
                            supportedAid1: this.form.cardScheme.supportedAid1,
                            partialNameFlag1: this.form.cardScheme.partialNameFlag1,
                            termAvn1: this.form.cardScheme.termAvn1,
                            secondTermAvn1: this.form.cardScheme.secondTermAvn1,
                            supportedAid2: this.form.cardScheme.supportedAid2,
                            partialNameFlag2: this.form.cardScheme.partialNameFlag2,
                            termAvn2: this.form.cardScheme.termAvn2,
                            secondTermAvn2: this.form.cardScheme.secondTermAvn2,
                            supportedAid3: this.form.cardScheme.supportedAid3,
                            partialNameFlag3: this.form.cardScheme.partialNameFlag3,
                            termAvn3: this.form.cardScheme.termAvn3,
                            secondTermAvn3: this.form.cardScheme.secondTermAvn3,
                            supportedAid4: this.form.cardScheme.supportedAid4,
                            partialNameFlag4: this.form.cardScheme.partialNameFlag4,
                            termAvn4: this.form.cardScheme.termAvn4,
                            secondTermAvn4: this.form.cardScheme.secondTermAvn4,
                            supportedAid5: this.form.cardScheme.supportedAid5,
                            partialNameFlag5: this.form.cardScheme.partialNameFlag5,
                            termAvn5: this.form.cardScheme.termAvn5,
                            secondTermAvn5: this.form.cardScheme.secondTermAvn5,
                            supportedAid6: this.form.cardScheme.supportedAid6,
                            partialNameFlag6: this.form.cardScheme.partialNameFlag6,
                            termAvn6: this.form.cardScheme.termAvn6,
                            secondTermAvn6: this.form.cardScheme.secondTermAvn6,
                            supportedAid7: this.form.cardScheme.supportedAid7,
                            partialNameFlag7: this.form.cardScheme.partialNameFlag7,
                            termAvn7: this.form.cardScheme.termAvn7,
                            secondTermAvn7: this.form.cardScheme.secondTermAvn7,
                            supportedAid8: this.form.cardScheme.supportedAid8,
                            partialNameFlag8: this.form.cardScheme.partialNameFlag8,
                            termAvn8: this.form.cardScheme.termAvn8,
                            secondTermAvn8: this.form.cardScheme.secondTermAvn8,
                            supportedAid9: this.form.cardScheme.supportedAid9,
                            partialNameFlag9: this.form.cardScheme.partialNameFlag9,
                            termAvn9: this.form.cardScheme.termAvn9,
                            secondTermAvn9: this.form.cardScheme.secondTermAvn9,
                            supportedAid10: this.form.cardScheme.supportedAid10,
                            partialNameFlag10: this.form.cardScheme.partialNameFlag10,
                            termAvn10: this.form.cardScheme.termAvn10,
                            secondTermAvn10: this.form.cardScheme.secondTermAvn10, 
                        });
                }
            }
            console.log(this.itemCS)
        },
        // add items on Emv
        addItemEmv() {
            if(!this.checkItemIfExisting(this.form.emv.schemeReference, this.itemsEmv)) {
                if (this.itemEmv < 2) {
                    this.$set(this.itemsEmv, this.itemEmv++, 
                    { 
                        emvId: this.form.emv.emvId, 
                        schemeReference: this.form.emv.schemeReference,
                        issuerReference: this.form.emv.issuerReference,
                        trmDataPresent: this.form.emv.trmDataPresent,
                        floorLimit: this.form.emv.floorLimit,
                        rsThreshold: this.form.emv.rsThreshold,
                        targetRs: this.form.emv.targetRs,
                        maxTargetRs: this.form.emv.maxTargetRs,
                        tacDefault: this.form.emv.tacDefault,
                        tacDenial: this.form.emv.tacDenial,
                        tacOnline: this.form.emv.tacOnline,
                        defaultTDOL: this.form.emv.defaultTDOL,
                        defaultDDOL: this.form.emv.defaultDDOL,
                        nextRecord: this.form.emv.nextRecord,
                        emvCount: this.form.emv.emvCount,
                        termCountryCode: this.form.emv.termCountryCode,
                        termCurrencyCode: this.form.emv.termCurrencyCode,
                        termCapabilities: this.form.emv.termCapabilities,
                        termAddCapabilities: this.form.emv.termAddCapabilities,
                        termType: this.form.emv.termType,
                        mcc: this.form.emv.mcc,
                    })
                } else {
                    ++this.itemEmv
                    this.itemsEmv.push(
                        { 
                            emvId: this.form.emv.emvId, 
                            schemeReference: this.form.emv.schemeReference,
                            issuerReference: this.form.emv.issuerReference,
                            trmDataPresent: this.form.emv.trmDataPresent,
                            floorLimit: this.form.emv.floorLimit,
                            rsThreshold: this.form.emv.rsThreshold,
                            targetRs: this.form.emv.targetRs,
                            maxTargetRs: this.form.emv.maxTargetRs,
                            tacDefault: this.form.emv.tacDefault,
                            tacDenial: this.form.emv.tacDenial,
                            tacOnline: this.form.emv.tacOnline,
                            defaultTDOL: this.form.emv.defaultTDOL,
                            defaultDDOL: this.form.emv.defaultDDOL,
                            nextRecord: this.form.emv.nextRecord,
                            emvCount: this.form.emv.emvCount,
                            termCountryCode: this.form.emv.termCountryCode,
                            termCurrencyCode: this.form.emv.termCurrencyCode,
                            termCapabilities: this.form.emv.termCapabilities,
                            termAddCapabilities: this.form.emv.termAddCapabilities,
                            termType: this.form.emv.termType,
                            mcc: this.form.emv.mcc,
                        });
                }
            }
            console.log(this.itemEmv)
        },
        viewCS(what, item = {}) {
            this.cardSchemeFormType = what
            this.selectedCardScheme = item
            this.showCSFormDialog = true
        },
        deleteItem(item, name) {
            if(name === 'cs') {
                const index = this.itemsCS.indexOf(item);
                --this.itemCS
                if (index !== -1) {
                    this.itemsCS.splice(index, 1);
                    if(this.itemsCS.length < 2) {
                        console.log(this.itemCS)
                        this.itemsCS.push({ id: this.itemCS, cardSchemeIndex: '', cardSchemeId: ''})
                    }
                }
            } else if (name === 'emv') {
                const index = this.itemsEmv.indexOf(item);
                --this.itemEmv
                if (index !== -1) {
                    this.itemsEmv.splice(index, 1);
                    if(this.itemsEmv.length < 2) {
                        console.log(this.itemCS)
                        this.itemsEmv.push({ id: this.itemEmv, emvId: '', schemeReference: ''})
                    }
                }
            }
        },
        viewEmv(what, item = {}) {
            this.emvFormType = what
            this.selectedEmv = item
            this.showEmvFormDialog = true
        },
        handleCurrencyChange(currency) {
            console.log('Selected currency:', currency);
            this.form.currency = { 
                country: currency.country,
                code: currency.code,
                name: currency.name,
                numCode: currency.numCode,
                symbol: currency.symbol,
            }
        },

        getTerminalMode: async function (val) {
            this.form.terminalMode = val
        },
        getPandateEntryMode: async function (val) {
            this.form.pandateEntryMode = val
        },
        getPinEntryCapability: async function (val) {
            this.form.pinEntryCapability = val
        },
        getPosConditionCode: async function (val) {
            this.form.posConditionCode = val
        },
        getPosTerminalType: async function (val) {
            this.form.posTerminalType = val
        },
        getPosTerminalEntryCapability: async function (val) {
            this.form.posTerminalEntryCapability = val
        },
        getLocation: async function (val) {
            this.form.location = val
        },
        getMotopaymentIndicator: async function (val) {
            this.form.motopaymentIndicator = val
        },
        getUnattendedAcceptanceTerminalIndicator: async function (val) {
            this.form.unattendedAcceptanceTerminalIndicator = val
        },
        getCardHolderidmethod: async function (val) {
            this.form.cardHolderidmethod = val
        },
        getServiceDevelopmentField: async function (val) {
            this.form.serviceDevelopmentField = val
        },
        getTypeOfPos: async function (val) {
            this.form.type = val
        },
        // getPosStatus: async function (val) {
        //     console.log('val', val)
        //         this.form.status = val
        // },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        closeDialogPrompt() {
            this.dialogVisible = false;
            this.dialog = false
            this.itemsCS = [
                { id: 1, cardSchemeIndex: '', cardSchemeId: ''},
                { id: 2, cardSchemeIndex: '', cardSchemeId: ''},
            ]
            this.itemCS = 0
            this.itemsEmv = [
                { id: 1, emvId: '', schemeReference: ''},
                { id: 2, emvId: '', schemeReference: ''},
            ]
            this.itemEmv = 0
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        close() {
            if(this.formType !== 'VIEW') {
                this.dialogVisible = true;
            } else {
                this.dialog = false
                this.itemsCS = [
                    { id: 1, cardSchemeIndex: '', cardSchemeId: ''},
                    { id: 2, cardSchemeIndex: '', cardSchemeId: ''},
                ]
                this.itemCS = 0
                this.itemsEmv = [
                    { id: 1, emvId: '', schemeReference: ''},
                    { id: 2, emvId: '', schemeReference: ''},
                ]
                this.itemEmv = 0
                this.$refs.formEntry.reset()
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
                this.$emit('close-dialog', this.formType)
            }
        },
        next(item) {
            console.log(this.form.terminalPassword)
            console.log("NUMBER E1:" + item)
            console.log("VALIDATE FORM 1: " + this.$refs.formEntryOne.validate())
            console.log("VALIDATE FORM 2: " + this.$refs.formEntryTwo.validate())
            console.log("VALIDATE FORM 3: " + this.$refs.formEntryThree.validate())
            console.log("VALIDATE FORM 4: " + this.$refs.formEntryFour.validate())
            // if(this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS')) {
                if ((item == 1 && this.formType == 'VIEW') || (item == 1 && this.$refs.formEntryOne.validate())) {
                    this.e1 = 2;
                } else if ((item == 2 && this.formType == 'VIEW') || (item == 2 && this.$refs.formEntryTwo.validate())) {
                    this.e1 = 3;
                } else if ((item == 3 && this.formType == 'VIEW') || (item == 3 && this.$refs.formEntryThree.validate())) {
                    this.e1 = 4
                } else if ((item == 4 && this.formType == 'VIEW') || (item == 4 && this.$refs.formEntryFour.validate())) {
                    this.e1 = 5;
                } else {
                    this.e1--;
                }
            // } else {
            //     if (item == 1 && this.$refs.formEntryOne.validate()) {
            //     this.e1 = 2;
            //     } else if (item == 2 && this.$refs.formEntryThree.validate()) {
            //     this.e1 = 3;
            //     } else if (item == 3 && this.$refs.formEntryFour.validate()) {
            //     this.e1 = 4;
            //     }
            // }
            },
        back(item) {
            if(item === 0) return 1
            // if(this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS')) {
                if (item == 5) {
                    this.e1 = 4;
                } else if (item == 4) {
                    this.e1 = 3;
                } else if (item == 3) {
                    this.e1 = 2;
                } else if (item == 2) {
                    this.e1 = 1;
                }
            // } else {
            //     if (item == 4) {
            //         this.e1 = 3;
            //     } else if (item == 3) {
            //         this.e1 = 2;
            //     } else if (item == 2) {
            //         this.e1 = 1;
            //     }
            // }
            },
        save() {
            if ((this.$refs.formEntryFive.validate() && this.$refs.formEntry.validate())) {
                this.isFormComplete = true;
                this.$emit('save-terminal', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        closeDialog(type) {
            console.log('type:', type);
            if (type === 'VIEW') {
                this.showCSFormDialog = false
                this.showEmvFormDialog = false
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
            this.itemsCS = []
            this.itemCS = 0, // Initial ID for new items
            this.itemsEmv = []
            this.itemEMV = 0
        },
        initializeForm() {
            this.e1 = 1;
            if (this.formType === 'CREATED') {
                const defaultCurrency = this.currencyList.find(currency => currency.country === 'Philippines');
                this.form = {
                    terminalId: null,
                    serialNum: null,
                    model: null,
                    type: null,
                    merchant: null,
                    branch: null,
                    deviceType: null,
                    currency: {
                        country: defaultCurrency.country ?? '',
                        code: defaultCurrency.code ?? '',
                        name: defaultCurrency.name ?? '',
                        numCode: defaultCurrency.numCode ?? '',
                        symbol: defaultCurrency.symbol ?? '',
                    },
                    cardScheme: null,
                    emv: null,
                    paymentAgent: null,
                    user: null,
                    network: null,
                    location: null,
                    pandateEntryMode: null,
                    posterminalType: '0',
                    bancnetAddress: null,
                    bancnetFixedFee: null,
                    bancnetMerchantSettlementBankCode: null,
                    bancnetTerminalOwner: null,
                    bancnetTerminalCode: null,
                    maximumAmount: null,
                    pinEntryCapability: null,
                    posTerminalEntryCapability: 'Contact & Contactless',
                    posConditionCode: null,
                    bancnetConvenienceFee: null,
                    simNetwork: null,
                    simCardNumber: null,
                    dateInstalled: null,
                    installedBy: null,
                    ipAddress: null,
                    status: null,
                    cardNetworkUsed: null,
                    motopaymentIndicator: null,
                    unattendedAcceptanceTerminalIndicator: null,
                    cardHolderidmethod: null,
                    serviceDevelopmentField: null,
                    terminalPassword: null,
                    autoSettlementFlag: false,
                    autoSettlementTime: null,
                    terminalPortNumber: null,
                    printSaleReceipt: false,
                    terminalMode: null,
                    terminalMacAddress: null,
                    merchantAssociation: null,
                    batchIndex: null,
                    batchLimit: 999,
                    batchNumber: null,
                    stan: null,
                    isEnabled: false,
                    emvContact: true,
                }
            } else if ((this.formType === 'UPDATE' || this.formType === 'VIEW') && this.selectedItem) {
                console.log("DATA", this.selectedItem)
                var selectedMerchants = []
                if (Array.isArray(this.selectedItem.merchantAssociation)) { 
                    this.selectedItem.merchantAssociation.forEach((merchantAssociation) => {
                        const matchingMerchantAssociation = this.merchantAssociationList.find((item) => item.merchantId === merchantAssociation.merchantId);
                        if (matchingMerchantAssociation != {}) {
                            const mappedMerchantAssociation = {
                                merchantId: matchingMerchantAssociation.merchantId,
                                name: matchingMerchantAssociation.name, 
                            };
                            selectedMerchants.push(mappedMerchantAssociation);
                        }
                    });
                }
                this.form = {
                    terminalId: this.selectedItem.terminalId,
                    serialNum: this.selectedItem.serialNum,
                    model: this.selectedItem.model,
                    type: this.selectedItem.type,
                    merchant: this.selectedItem.merchant ?? {},
                    branch: this.selectedItem.branch ?? {},
                    deviceType: { code: this.selectedItem.deviceType.code, name: this.selectedItem.deviceType.name } ?? {},
                    currency: this.selectedItem.currency ?? {},
                    cardScheme: this.selectedItem.cardScheme ?? {},
                    emv: this.selectedItem.emv ?? {},
                    paymentAgent: this.selectedItem.paymentAgent ?? {},
                    user: this.selectedItem.user ?? {},
                    network: this.selectedItem.network,
                    location: this.selectedItem.location,
                    pandateEntryMode: this.selectedItem.pandateEntryMode,
                    posterminalType: this.selectedItem.posterminalType,
                    bancnetAddress: this.selectedItem.bancnetAddress,
                    bancnetFixedFee: this.selectedItem.bancnetFixedFee,
                    bancnetMerchantSettlementBankCode: this.selectedItem.bancnetMerchantSettlementBankCode,
                    bancnetTerminalOwner: this.selectedItem.bancnetTerminalOwner,
                    bancnetTerminalCode: this.selectedItem.bancnetTerminalCode,
                    maximumAmount: this.selectedItem.maximumAmount,
                    pinEntryCapability: this.selectedItem.pinEntryCapability,
                    posTerminalEntryCapability: this.posEntryCapabilitiesOptions(this.selectedItem.posTerminalEntryCapability),
                    posConditionCode: this.selectedItem.posConditionCode,
                    bancnetConvenienceFee: this.selectedItem.bancnetConvenienceFee,
                    simNetwork: this.selectedItem.simNetwork,
                    simCardNumber: this.selectedItem.simCardNumber,
                    dateInstalled: this.selectedItem.dateInstalled,
                    installedBy: this.selectedItem.installedBy,
                    ipAddress: this.selectedItem.ipAddress,
                    status: this.selectedItem.status,
                    cardNetworkUsed: this.selectedItem.cardNetworkUsed,
                    motopaymentIndicator: this.selectedItem.motopaymentIndicator,
                    unattendedAcceptanceTerminalIndicator: this.selectedItem.unattendedAcceptanceTerminalIndicator,
                    cardHolderidmethod: this.selectedItem.cardHolderidmethod,
                    serviceDevelopmentField: this.selectedItem.serviceDevelopmentField,
                    // terminalPassword: this.selectedItem.password, 
                    terminalPassword: this.selectedItem.password !== "" ? "*****" : this.selectedItem.password,
                    autoSettlementFlag: this.selectedItem.auto_settlement,
                    autoSettlementTime: this.selectedItem.autoSettlementTime, 
                    terminalPortNumber: this.selectedItem.terminalPort,
                    printSaleReceipt: this.selectedItem.printSaleReceipt, 
                    terminalMode: this.selectedItem.mode, 
                    terminalMacAddress: this.selectedItem.mac,
                    merchantAssociation: selectedMerchants ?? {},
                    batchIndex: this.selectedItem.batch.batchIndex,
                    batchLimit: this.selectedItem.batch.batchLimit ?? 999,
                    batchNumber: this.selectedItem.batch.batchNumber,
                    stan: this.selectedItem.batch.stan,
                    isEnabled: this.selectedItem.isEnabled,
                    emvContact: this.selectedItem.emvContact
                }   
                // only working when [{}, {}]
                if (Array.isArray(this.form.cardScheme)) { 
                    this.form.cardScheme.forEach((cardScheme) => {
                        const matchingCS = this.cardSchemeList.find((item) => item.cardSchemeId.toString() === cardScheme.cardSchemeId.toString());
                        const check = this.itemsCS.some(item => item.cardSchemeId.toString() === matchingCS.cardSchemeId.toString());
                        if (matchingCS != {} && !check) {
                            const mappedCS = {
                                // id: this.itemCS,
                                acquirer: matchingCS.acquirer,
                                cardSchemeIndex: matchingCS.cardSchemeIndex,
                                displayText: matchingCS.displayText,
                                cardSchemeId: matchingCS.cardSchemeId,
                                eptRecord: matchingCS.eptRecord,
                                rid: matchingCS.rid,
                                capkIndex1: matchingCS.capkIndex1,
                                capkFile1: matchingCS.capkFile1,
                                capkExptDate1: matchingCS.capkExptDate1,
                                capkIndex2: matchingCS.capkIndex2,
                                capkFile2: matchingCS.capkFile2,
                                capkExptDate2: matchingCS.capkExptDate2,
                                capkIndex3: matchingCS.capkIndex3,
                                capkFile3: matchingCS.capkFile3,
                                capkExptDate3: matchingCS.capkExptDate3,
                                capkIndex4: matchingCS.capkIndex4,
                                capkFile4: matchingCS.capkFile4,
                                capkExptDate4: matchingCS.capkExptDate4,
                                capkIndex5: matchingCS.capkIndex5,
                                capkFile5: matchingCS.capkFile5,
                                capkExptDate5: matchingCS.capkExptDate5,
                                capkIndex6: matchingCS.capkIndex6,
                                capkFile6: matchingCS.capkFile6,
                                capkExptDate6: matchingCS.capkExptDate6,
                                capkIndex7: matchingCS.capkIndex7,
                                capkFile7: matchingCS.capkFile7,
                                capkExptDate7: matchingCS.capkExptDate7,
                                capkIndex8: matchingCS.capkIndex8,
                                capkFile8: matchingCS.capkFile8,
                                capkExptDate8: matchingCS.capkExptDate8,
                                capkIndex9: matchingCS.capkIndex9,
                                capkFile9: matchingCS.capkFile9,
                                capkExptDate9: matchingCS.capkExptDate9,
                                capkIndex10: matchingCS.capkIndex10,
                                capkFile10: matchingCS.capkFile10,
                                capkExptDate10: matchingCS.capkExptDate10,
                                supportedAid1: matchingCS.supportedAid1,
                                partialNameFlag1: matchingCS.partialNameFlag1,
                                termAvn1: matchingCS.termAvn1,
                                secondTermAvn1: matchingCS.secondTermAvn1,
                                supportedAid2: matchingCS.supportedAid2,
                                partialNameFlag2: matchingCS.partialNameFlag2,
                                termAvn2: matchingCS.termAvn2,
                                secondTermAvn2: matchingCS.secondTermAvn2,
                                supportedAid3: matchingCS.supportedAid3,
                                partialNameFlag3: matchingCS.partialNameFlag3,
                                termAvn3: matchingCS.termAvn3,
                                secondTermAvn3: matchingCS.secondTermAvn3,
                                supportedAid4: matchingCS.supportedAid4,
                                partialNameFlag4: matchingCS.partialNameFlag4,
                                termAvn4: matchingCS.termAvn4,
                                secondTermAvn4: matchingCS.secondTermAvn4,
                                supportedAid5: matchingCS.supportedAid5,
                                partialNameFlag5: matchingCS.partialNameFlag5,
                                termAvn5: matchingCS.termAvn5,
                                secondTermAvn5: matchingCS.secondTermAvn5,
                                supportedAid6: matchingCS.supportedAid6,
                                partialNameFlag6: matchingCS.partialNameFlag6,
                                termAvn6: matchingCS.termAvn6,
                                secondTermAvn6: matchingCS.secondTermAvn6,
                                supportedAid7: matchingCS.supportedAid7,
                                partialNameFlag7: matchingCS.partialNameFlag7,
                                termAvn7: matchingCS.termAvn7,
                                secondTermAvn7: matchingCS.secondTermAvn7,
                                supportedAid8: matchingCS.supportedAid8,
                                partialNameFlag8: matchingCS.partialNameFlag8,
                                termAvn8: matchingCS.termAvn8,
                                secondTermAvn8: matchingCS.secondTermAvn8,
                                supportedAid9: matchingCS.supportedAid9,
                                partialNameFlag9: matchingCS.partialNameFlag9,
                                termAvn9: matchingCS.termAvn9,
                                secondTermAvn9: matchingCS.secondTermAvn9,
                                supportedAid10: matchingCS.supportedAid10,
                                partialNameFlag10: matchingCS.partialNameFlag10,
                                termAvn10: matchingCS.termAvn10,
                                secondTermAvn10: matchingCS.secondTermAvn10,
                            };
                            console.log("MAPPED CS", mappedCS)
                            this.$set(this.itemsCS, this.itemCS++, mappedCS);
                        }
                    });
                } 
                if (Array.isArray(this.form.emv)) { 
                    this.form.emv.forEach((emv) => {
                        const matchingEmv = this.emvList.find((item) => item.schemeReference.toString() === emv.schemeReference.toString());
                        const check = this.itemsEmv.some(item => item.schemeReference.toString() === matchingEmv.schemeReference.toString());
                        if (matchingEmv != {} && !check) {
                            const mappedEmv = {
                                // id: this.itemEmv,
                                emvId: matchingEmv.emvId, 
                                schemeReference: matchingEmv.schemeReference,
                                issuerReference: matchingEmv.issuerReference,
                                trmDataPresent: matchingEmv.trmDataPresent,
                                floorLimit: matchingEmv.floorLimit,
                                rsThreshold: matchingEmv.rsThreshold,
                                targetRs: matchingEmv.targetRs,
                                maxTargetRs: matchingEmv.maxTargetRs,
                                tacDefault: matchingEmv.tacDefault,
                                tacDenial: matchingEmv.tacDenial,
                                tacOnline: matchingEmv.tacOnline,
                                defaultTDOL: matchingEmv.defaultTDOL,
                                defaultDDOL: matchingEmv.defaultDDOL,
                                nextRecord: matchingEmv.nextRecord,
                                emvCount: matchingEmv.emvCount,
                                termCountryCode: matchingEmv.termCountryCode,
                                termCurrencyCode: matchingEmv.termCurrencyCode,
                                termCapabilities: matchingEmv.termCapabilities,
                                termAddCapabilities: matchingEmv.termAddCapabilities,
                                termType: matchingEmv.termType,
                                mcc: matchingEmv.mcc,
                            };
                            this.$set(this.itemsEmv, this.itemEmv++, mappedEmv);
                        }
                    });
                }
                try {
                    this.form.currency = JSON.parse(this.selectedItem.currency)
                } catch (error) {
                    console.error("JSON parsing error:", error);
                }
                if(this.form.currency == {} || this.form.currency == null || this.form.currency === "" || this.form.currency === "PHP") {
                    const defaultCurrency = this.currencyList.find((currency) => currency.country === 'Philippines');
                    if (defaultCurrency != {}) {
                        this.form.currency = {
                            country: defaultCurrency.country,
                            code: defaultCurrency.code,
                            name: defaultCurrency.name,
                            numCode: defaultCurrency.numCode,
                            symbol: defaultCurrency.symbol,
                        };
                    }
                }
            }
        }
    }
}
</script>
<style>
.custom-row-height {
  min-height: 80px; /* Adjust the height as needed */
}
</style>